import { ChangeEvent } from "react";

import { toDecimal, isNumberString } from ".";

export const normalizedNumberStringOnChange = (
  event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
) => {
  const value = event.target.value.replace("$", "");
  const checkingValue = value === "-" ? -1 : Number(value);
  if (!isFinite(checkingValue) || isNaN(checkingValue)) {
    return;
  }
  return value;
};

export const normalizedNumberStringOnBlur = (
  event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  allowNegativeNumbers: boolean = false,
  amountLimit?: number | string,
  limitationValue?: number | string,
) => {
  const valueString = event.target.value.replace("$", "");
  if (valueString === "") return valueString;
  let value = parseFloat(valueString);
  if (!isFinite(value) || isNaN(value)) {
    value = 0;
  }
  const resultValue = toDecimal(value).toDP(8);
  const decimalPlaces = resultValue.dp();
  if (amountLimit && resultValue.gt(amountLimit)) {
    return toDecimal(
      isNumberString(String(limitationValue))
        ? (limitationValue as number | string)
        : amountLimit,
    ).toFixed(8);
  }
  return allowNegativeNumbers
    ? resultValue.toFixed(decimalPlaces)
    : resultValue.abs().toFixed(decimalPlaces);
};
