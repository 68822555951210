import { createTimeouts } from "./createTimeout";

const { clearTimeouts, pushTimeout } = createTimeouts();

export const throttle = (
  notNecessarily = false,
  /* set true, if callBack is necessarily for return */
) => {
  let lastTime = 0;

  return (f: Function, timer: number): void => {
    const now = Date.now();
    if (now - lastTime >= timer) {
      lastTime = now;
      f();
      clearTimeouts();
    } else if (!notNecessarily) {
      clearTimeouts();
      pushTimeout(() => {
        f();
        lastTime = now;
      }, timer);
    }
  };
};
