export const replaceLetters = (value: string) => {
  if (!value) {
    return value || "";
  }
  let edit = value.replace(/[^(\d+(?:.\-\d{1,2})?)$]/g, "");

  return edit.replace(/,{1,}|\.{2,}/g, ".");
};

export const replaceNumbers = (num: string) => {
  if (!num) {
    return num || "";
  }
  const result = num.match(/^[^.]+?(?=\.0*$)|^[^.]+?\..*?(?=0*$)|^[^.]*$/g);

  return result ? result[0] : num;
};
