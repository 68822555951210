import { Decimal, toDecimal } from "./";

const DECIMAL_ZERO = toDecimal(0);

export class PnlCalculator {
  private pnlAmount: Decimal;
  private blockAddProfit: boolean;

  constructor() {
    this.pnlAmount = DECIMAL_ZERO;
    this.blockAddProfit = false;
  }

  public set block(isBlock: boolean) {
    this.blockAddProfit = isBlock;
  }

  public get block() {
    return this.blockAddProfit;
  }

  public get pnl() {
    return this.pnlAmount;
  }

  public resetPnl() {
    this.pnlAmount = DECIMAL_ZERO;
  }

  public addProfit(profit: Decimal | string) {
    if (this.blockAddProfit) return;
    this.pnlAmount = this.pnlAmount.add(profit);
  }

  public isPnlOverLimit(stopOnValueString: string, type: "loss" | "profit") {
    const stopOn = Number(stopOnValueString);
    const pnl = type === "loss" ? this.pnlAmount.mul(-1) : this.pnlAmount;
    if (
      !isNaN(stopOn) &&
      isFinite(stopOn) &&
      stopOnValueString !== "" &&
      stopOn > 0 &&
      !pnl.eq(0)
    ) {
      return pnl.gte(stopOn);
    }
    return false;
  }
}
