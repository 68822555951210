import { createIntervals } from "utils";

export const whenDocumentReady = (callBack: () => void, time = 100) => {
  const { clearIntervals, pushInterval } = createIntervals();

  pushInterval(() => {
    if (document.readyState === "complete") {
      callBack();
      clearIntervals();
    }
  }, time);
};
