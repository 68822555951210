import { createIntervals } from ".";

export const whenDocumentLoaded = (
  f: () => void,
  state: DocumentReadyState = "complete",
) => {
  const { clearIntervals, pushInterval } = createIntervals();

  pushInterval(() => {
    if (document.readyState === state) {
      f();
      clearIntervals();
    }
  }, 100);

  return clearIntervals;
};
