export default function replaceStringAt(
  baseString: string,
  replaceString: string,
  index: number,
) {
  return (
    baseString.substr(0, index) +
    replaceString +
    baseString.substr(index + 1, baseString.length)
  );
}
