import matchAll from "match-all";
// import replaceall from "replaceall";
import replaceall from "string-replace-all";

import { emojiList } from "components/Chat/components/Write/data";
import { isDevelop } from "./isDevelopment";

export const cdnEmojiUrl = "https://cdn.pasino.com/emoji";

const findSmileReg =
  /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g;

export const smileToImg = (smile: string) => {
  if (smile.length >= 4) {
    return `<img class="emojione" alt="${smile}" title=":${smile}:" src="${cdnEmojiUrl}/${smile}.png">`;
  } else {
    const smileData = emojiList.find((value) => value.smile === smile);

    if (smileData) {
      return `<img class="emojione" alt="${smileData.smile}" src="${cdnEmojiUrl}/${smileData.base}.png" >`;
    } else {
    }
    return smile;
  }
};

export const reduceSmileString = (text: string) => {
  let returnTextValue = text;
  const matchedSmile: string[] = matchAll(text, findSmileReg).toArray();
  const smiles = new Set<string>();

  matchedSmile.forEach((emoji, i) => {
    if (isDevelop() && typeof emoji !== "string") {
      console.log("matchedSmile error!");
      console.error("matchedSmile error!");
    }

    smiles.add(emoji);
  });

  smiles.forEach((emoji) => {
    const convertedEmoji = smileToImg(emoji);
    returnTextValue = replaceall(returnTextValue, emoji, convertedEmoji);
  });

  return returnTextValue;
};
