import { LinkProps } from "react-router-dom";

const urlRegEx = /(.*)(\?.*)/;

export const createLinkParamsFromString = (url?: string) => {
  let [link, pathname, search] = url?.match(urlRegEx) || ["", "", ""];

  if (!pathname && url) {
    pathname = url;
  }

  const result: LinkProps["to"] = {
    pathname,
    search,
  };

  return result;
};
