import type { ScrollState } from "react-scrollbars-custom/dist/types/types";

export function isTouchEvent(event: Event | any): event is TouchEvent {
  return !!event.touches;
}

export function isKeyboardEvent(event: Event | any): event is KeyboardEvent {
  return !!event.key;
}

export function isScrollStateEvent(event: Event | any): event is ScrollState {
  return !!event.contentScrollHeight;
}

export function isDivElement(
  event: EventTarget | any,
): event is HTMLDivElement {
  return !!event.className;
}

export function createGuard<Type>(checkedKey: string) {
  return function (value: Type | any): value is Type {
    if (!value) {
      return false;
    }

    //@ts-ignore
    const result = (value as Type)[checkedKey];

    return !!result || result === 0;
  };
}
