import { isDevelop } from "utils/isDevelopment";

const isDevelopment = isDevelop();

export const prepareError = (err: any, trace?: string) => {
  try {
    const typeError = isDevelopment ? "~~~~Error on DEV~~~~ " : "--";
    if (typeof err === "string") {
      err = `${typeError}
> ${err}
> * ${trace}`;
    } else if (!err) {
      err = `> ${typeError}
> * ${trace}`;
    } else if (typeof err === "object") {
      if (err.message || err.stack || err.name) {
        err = ` > message: ${err.message}
> name: ${err.name}
> stack: ${err.stack}
`;
      } else if (err?.toString) {
        err = err.toString();
      } else {
        err = `> ${JSON.stringify(err)}
> * ${trace}`;
      }
    } else {
      err = "unsupported error type: " + typeof err;
    }

    return err;
  } catch (error) {
    console.error(">> Error on prepareError: ", error);

    return "Error on prepareError " + trace;
  }
};
