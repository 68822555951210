import { detectRobots } from "./detectRobots";

export const prepareRender = async (renderComponent: () => void) => {
  if (!detectRobots()) return renderComponent();
  const body = document.getElementById("root");

  const { location } = window;
  if (body)
    body.innerHTML = `
      <div style="
        width: 100vw;
        height: 100vh;
        background: #301b52;
      "></div>`;

  const { robotsRedirectConfig } = await import("./config");
  let htmlContent: null | {
    head: string;
    body: string;
    callBack?: () => Promise<void>;
  } = null;

  try {
    const loadingFunction = robotsRedirectConfig[location.pathname];
    //@ts-ignore
    if (loadingFunction) {
      const { default: content } = await loadingFunction();
      htmlContent = content;
    }
  } catch (error) {
    console.error("!>>", error);
  }

  if (htmlContent) {
    const head = document.querySelector("head");
    if (head && body) {
      head.innerHTML = htmlContent.head;
      body.innerHTML = htmlContent.body;

      if (htmlContent.callBack) htmlContent.callBack();
    }
  } else {
    renderComponent();
  }
};
