import matchAll from "match-all";

import replaceStringAt from "./replaceStringAt";

const spacingReg = /\s+|-+/g;

export const letterFormat = (value: string, isEach?: boolean) => {
  const lower = value.toLowerCase(),
    firstLtr = value[0];
  let result = lower;

  result = firstLtr + lower.slice(1);

  if (isEach) {
    const matched: any[] = matchAll(lower, spacingReg).toArray();
    if (matched?.length) {
      matched.forEach((value) => {
        const bigLetter = result[value.index + 1].toUpperCase();
        result = replaceStringAt(result, bigLetter, value.index + 1);
      });
    }
  }

  return result;
};
