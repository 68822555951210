import { track } from "./track";

export const setCursor = (element: HTMLElement, position: number) => {
  const range = document.createRange();
  const selection = window.getSelection();

  try {
    range.setStart(element, position);
    range.collapse(true);
    selection?.removeAllRanges();
    selection?.addRange(range);
  } catch (error) {
    track()?.errors(error, "setCursor", {
      custom: {
        position,
        element: element.tagName,
        children: element.childNodes.length,
      },
    });

    console.error(
      `Can't set cursor on ${position} position.
      ChildNodes: ${element.childNodes}`,
    );
  }
};
