export const isInIFrame = () => window.location !== window.parent.location;

export const isRecursion = () => {
  try {
    const isIframe = !(
      window.location === window.parent.location || window.self === window.top
    );
    const isRecursion = window.location.host === window.parent.location.host;

    return isIframe && isRecursion;
  } catch {
    return false;
  }
};
