import { track } from "./track";

export interface CoinConfiguration {
  coin: string;
  minimum_bet: string;
  maximum_bet: string;
}

interface ConfigurationResult {
  coins: Array<CoinConfiguration>;
  success: boolean;
  error?: any;
}

export const getCoinsConfiguration = async (
  game: string,
): Promise<ConfigurationResult> => {
  try {
    const coinsConfigResponse = await fetch(
      `${process.env.REACT_APP_URL}/${game}/get-configuration`,
    );
    const coinsConfigResponseBody: {
      coins: Array<CoinConfiguration>;
      success: boolean;
    } = await coinsConfigResponse.json();
    return coinsConfigResponseBody;
  } catch (error) {
    track()?.errors(error, "getCoinsConfiguration", {
      custom: {
        game,
      },
    });

    return {
      success: false,
      error,
      coins: [],
    };
  }
};
