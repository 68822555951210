export const emojiList = [
  { category: "people", base: "1f600", smile: "😀" },
  { category: "people", base: "1f603", smile: "😃" },
  { category: "people", base: "1f604", smile: "😄" },
  { category: "people", base: "1f601", smile: "😁" },
  { category: "people", base: "1f606", smile: "😆" },
  { category: "people", base: "1f605", smile: "😅" },
  { category: "people", base: "1f602", smile: "😂" },
  { category: "people", base: "1f923", smile: "🤣" },
  { category: "people", base: "263a", smile: "☺" },
  { category: "people", base: "1f60a", smile: "😊" },
  { category: "people", base: "1f607", smile: "😇" },
  { category: "people", base: "1f642", smile: "🙂" },
  { category: "people", base: "1f643", smile: "🙃" },
  { category: "people", base: "1f609", smile: "😉" },
  { category: "people", base: "1f60c", smile: "😌" },
  { category: "people", base: "1f60d", smile: "😍" },
  { category: "people", base: "1f970", smile: "🥰" },
  { category: "people", base: "1f618", smile: "😘" },
  { category: "people", base: "1f617", smile: "😗" },
  { category: "people", base: "1f619", smile: "😙" },
  { category: "people", base: "1f61a", smile: "😚" },
  { category: "people", base: "1f60b", smile: "😋" },
  { category: "people", base: "1f61b", smile: "😛" },
  { category: "people", base: "1f61d", smile: "😝" },
  { category: "people", base: "1f61c", smile: "😜" },
  { category: "people", base: "1f92a", smile: "🤪" },
  { category: "people", base: "1f928", smile: "🤨" },
  { category: "people", base: "1f9d0", smile: "🧐" },
  { category: "people", base: "1f913", smile: "🤓" },
  { category: "people", base: "1f60e", smile: "😎" },
  { category: "people", base: "1f929", smile: "🤩" },
  { category: "people", base: "1f973", smile: "🥳" },
  { category: "people", base: "1f60f", smile: "😏" },
  { category: "people", base: "1f612", smile: "😒" },
  { category: "people", base: "1f61e", smile: "😞" },
  { category: "people", base: "1f614", smile: "😔" },
  { category: "people", base: "1f61f", smile: "😟" },
  { category: "people", base: "1f615", smile: "😕" },
  { category: "people", base: "1f641", smile: "🙁" },
  { category: "people", base: "2639", smile: "☹" },
  { category: "people", base: "1f623", smile: "😣" },
  { category: "people", base: "1f616", smile: "😖" },
  { category: "people", base: "1f62b", smile: "😫" },
  { category: "people", base: "1f629", smile: "😩" },
  { category: "people", base: "1f97a", smile: "🥺" },
  { category: "people", base: "1f622", smile: "😢" },
  { category: "people", base: "1f62d", smile: "😭" },
  { category: "people", base: "1f624", smile: "😤" },
  { category: "people", base: "1f620", smile: "😠" },
  { category: "people", base: "1f621", smile: "😡" },
  { category: "people", base: "1f92c", smile: "🤬" },
  { category: "people", base: "1f92f", smile: "🤯" },
  { category: "people", base: "1f633", smile: "😳" },
  { category: "people", base: "1f975", smile: "🥵" },
  { category: "people", base: "1f976", smile: "🥶" },
  { category: "people", base: "1f631", smile: "😱" },
  { category: "people", base: "1f628", smile: "😨" },
  { category: "people", base: "1f630", smile: "😰" },
  { category: "people", base: "1f625", smile: "😥" },
  { category: "people", base: "1f613", smile: "😓" },
  { category: "people", base: "1f917", smile: "🤗" },
  { category: "people", base: "1f914", smile: "🤔" },
  { category: "people", base: "1f92d", smile: "🤭" },
  { category: "people", base: "1f92b", smile: "🤫" },
  { category: "people", base: "1f925", smile: "🤥" },
  { category: "people", base: "1f636", smile: "😶" },
  { category: "people", base: "1f610", smile: "😐" },
  { category: "people", base: "1f611", smile: "😑" },
  { category: "people", base: "1f62c", smile: "😬" },
  { category: "people", base: "1f644", smile: "🙄" },
  { category: "people", base: "1f62f", smile: "😯" },
  { category: "people", base: "1f626", smile: "😦" },
  { category: "people", base: "1f627", smile: "😧" },
  { category: "people", base: "1f62e", smile: "😮" },
  { category: "people", base: "1f632", smile: "😲" },
  { category: "people", base: "1f634", smile: "😴" },
  { category: "people", base: "1f924", smile: "🤤" },
  { category: "people", base: "1f62a", smile: "😪" },
  { category: "people", base: "1f635", smile: "😵" },
  { category: "people", base: "1f910", smile: "🤐" },
  { category: "people", base: "1f974", smile: "🥴" },
  { category: "people", base: "1f922", smile: "🤢" },
  { category: "people", base: "1f92e", smile: "🤮" },
  { category: "people", base: "1f927", smile: "🤧" },
  { category: "people", base: "1f637", smile: "😷" },
  { category: "people", base: "1f912", smile: "🤒" },
  { category: "people", base: "1f915", smile: "🤕" },
  { category: "people", base: "1f911", smile: "🤑" },
  { category: "people", base: "1f920", smile: "🤠" },
  { category: "people", base: "1f608", smile: "😈" },
  { category: "people", base: "1f47f", smile: "👿" },
  { category: "people", base: "1f479", smile: "👹" },
  { category: "people", base: "1f47a", smile: "👺" },
  { category: "people", base: "1f921", smile: "🤡" },
  { category: "people", base: "1f4a9", smile: "💩" },
  { category: "people", base: "1f47b", smile: "👻" },
  { category: "people", base: "1f480", smile: "💀" },
  { category: "people", base: "2620", smile: "☠" },
  { category: "people", base: "1f47d", smile: "👽" },
  { category: "people", base: "1f47e", smile: "👾" },
  { category: "people", base: "1f916", smile: "🤖" },
  { category: "people", base: "1f383", smile: "🎃" },
  { category: "people", base: "1f63a", smile: "😺" },
  { category: "people", base: "1f638", smile: "😸" },
  { category: "people", base: "1f639", smile: "😹" },
  { category: "people", base: "1f63b", smile: "😻" },
  { category: "people", base: "1f63c", smile: "😼" },
  { category: "people", base: "1f63d", smile: "😽" },
  { category: "people", base: "1f640", smile: "🙀" },
  { category: "people", base: "1f63f", smile: "😿" },
  { category: "people", base: "1f63e", smile: "😾" },
  { category: "people", base: "1f932", smile: "🤲" },
  { category: "people", base: "1f450", smile: "👐" },
  { category: "people", base: "1f64c", smile: "🙌" },
  { category: "people", base: "1f44f", smile: "👏" },
  { category: "people", base: "1f91d", smile: "🤝" },
  { category: "people", base: "1f44d", smile: "👍" },
  { category: "people", base: "1f44e", smile: "👎" },
  { category: "people", base: "1f44a", smile: "👊" },
  { category: "people", base: "270a", smile: "✊" },
  { category: "people", base: "1f91b", smile: "🤛" },
  { category: "people", base: "1f91c", smile: "🤜" },
  { category: "people", base: "1f91e", smile: "🤞" },
  { category: "people", base: "270c", smile: "✌" },
  { category: "people", base: "1f91f", smile: "🤟" },
  { category: "people", base: "1f918", smile: "🤘" },
  { category: "people", base: "1f44c", smile: "👌" },
  { category: "people", base: "1f448", smile: "👈" },
  { category: "people", base: "1f449", smile: "👉" },
  { category: "people", base: "1f446", smile: "👆" },
  { category: "people", base: "1f447", smile: "👇" },
  { category: "people", base: "261d", smile: "☝" },
  { category: "people", base: "270b", smile: "✋" },
  { category: "people", base: "1f91a", smile: "🤚" },
  { category: "people", base: "1f590", smile: "🖐" },
  { category: "people", base: "1f596", smile: "🖖" },
  { category: "people", base: "1f44b", smile: "👋" },
  { category: "people", base: "1f919", smile: "🤙" },
  { category: "people", base: "1f4aa", smile: "💪" },
  { category: "people", base: "1f595", smile: "🖕" },
  { category: "people", base: "270d", smile: "✍" },
  { category: "people", base: "1f64f", smile: "🙏" },
  { category: "people", base: "1f9b6", smile: "🦶" },
  { category: "people", base: "1f9b5", smile: "🦵" },
  { category: "people", base: "1f484", smile: "💄" },
  { category: "people", base: "1f48b", smile: "💋" },
  { category: "people", base: "1f444", smile: "👄" },
  { category: "people", base: "1f9b7", smile: "🦷" },
  { category: "people", base: "1f9b4", smile: "🦴" },
  { category: "people", base: "1f445", smile: "👅" },
  { category: "people", base: "1f442", smile: "👂" },
  { category: "people", base: "1f443", smile: "👃" },
  { category: "people", base: "1f463", smile: "👣" },
  { category: "people", base: "1f441", smile: "👁" },
  { category: "people", base: "1f440", smile: "👀" },
  { category: "people", base: "1f9e0", smile: "🧠" },
  { category: "people", base: "1f5e3", smile: "🗣" },
  { category: "people", base: "1f464", smile: "👤" },
  { category: "people", base: "1f465", smile: "👥" },
  { category: "people", base: "1f476", smile: "👶" },
  { category: "people", base: "1f467", smile: "👧" },
  { category: "people", base: "1f9d2", smile: "🧒" },
  { category: "people", base: "1f466", smile: "👦" },
  { category: "people", base: "1f469", smile: "👩" },
  { category: "people", base: "1f9d1", smile: "🧑" },
  { category: "people", base: "1f468", smile: "👨" },
  { category: "people", base: "1f471", smile: "👱" },
  { category: "people", base: "1f9d4", smile: "🧔" },
  { category: "people", base: "1f475", smile: "👵" },
  { category: "people", base: "1f9d3", smile: "🧓" },
  { category: "people", base: "1f474", smile: "👴" },
  { category: "people", base: "1f472", smile: "👲" },
  { category: "people", base: "1f473", smile: "👳" },
  { category: "people", base: "1f9d5", smile: "🧕" },
  { category: "people", base: "1f46e", smile: "👮" },
  { category: "people", base: "1f477", smile: "👷" },
  { category: "people", base: "1f482", smile: "💂" },
  { category: "people", base: "1f575", smile: "🕵" },
  { category: "people", base: "1f478", smile: "👸" },
  { category: "people", base: "1f934", smile: "🤴" },
  { category: "people", base: "1f9b8", smile: "🦸" },
  { category: "people", base: "1f9b9", smile: "🦹" },
  { category: "people", base: "1f936", smile: "🤶" },
  { category: "people", base: "1f385", smile: "🎅" },
  { category: "people", base: "1f9d9", smile: "🧙" },
  { category: "people", base: "1f9dd", smile: "🧝" },
  { category: "people", base: "1f9db", smile: "🧛" },
  { category: "people", base: "1f9df", smile: "🧟" },
  { category: "people", base: "1f9de", smile: "🧞" },
  { category: "people", base: "1f9dc", smile: "🧜" },
  { category: "people", base: "1f9da", smile: "🧚" },
  { category: "people", base: "1f47c", smile: "👼" },
  { category: "people", base: "1f930", smile: "🤰" },
  { category: "people", base: "1f931", smile: "🤱" },
  { category: "people", base: "1f647", smile: "🙇" },
  { category: "people", base: "1f481", smile: "💁" },
  { category: "people", base: "1f645", smile: "🙅" },
  { category: "people", base: "1f646", smile: "🙆" },
  { category: "people", base: "1f64b", smile: "🙋" },
  { category: "people", base: "1f926", smile: "🤦" },
  { category: "people", base: "1f937", smile: "🤷" },
  { category: "people", base: "1f64e", smile: "🙎" },
  { category: "people", base: "1f64d", smile: "🙍" },
  { category: "people", base: "1f487", smile: "💇" },
  { category: "people", base: "1f486", smile: "💆" },
  { category: "people", base: "1f9d6", smile: "🧖" },
  { category: "people", base: "1f485", smile: "💅" },
  { category: "people", base: "1f933", smile: "🤳" },
  { category: "people", base: "1f483", smile: "💃" },
  { category: "people", base: "1f57a", smile: "🕺" },
  { category: "people", base: "1f46f", smile: "👯" },
  { category: "people", base: "1f574", smile: "🕴" },
  { category: "people", base: "1f6b6", smile: "🚶" },
  { category: "people", base: "1f3c3", smile: "🏃" },
  { category: "people", base: "1f46b", smile: "👫" },
  { category: "people", base: "1f46d", smile: "👭" },
  { category: "people", base: "1f46c", smile: "👬" },
  { category: "people", base: "1f491", smile: "💑" },
  { category: "people", base: "1f48f", smile: "💏" },
  { category: "people", base: "1f46a", smile: "👪" },
  { category: "people", base: "1f9f6", smile: "🧶" },
  { category: "people", base: "1f9f5", smile: "🧵" },
  { category: "people", base: "1f9e5", smile: "🧥" },
  { category: "people", base: "1f97c", smile: "🥼" },
  { category: "people", base: "1f45a", smile: "👚" },
  { category: "people", base: "1f455", smile: "👕" },
  { category: "people", base: "1f456", smile: "👖" },
  { category: "people", base: "1f454", smile: "👔" },
  { category: "people", base: "1f457", smile: "👗" },
  { category: "people", base: "1f459", smile: "👙" },
  { category: "people", base: "1f458", smile: "👘" },
  { category: "people", base: "1f97f", smile: "🥿" },
  { category: "people", base: "1f460", smile: "👠" },
  { category: "people", base: "1f461", smile: "👡" },
  { category: "people", base: "1f462", smile: "👢" },
  { category: "people", base: "1f45e", smile: "👞" },
  { category: "people", base: "1f45f", smile: "👟" },
  { category: "people", base: "1f97e", smile: "🥾" },
  { category: "people", base: "1f9e6", smile: "🧦" },
  { category: "people", base: "1f9e4", smile: "🧤" },
  { category: "people", base: "1f9e3", smile: "🧣" },
  { category: "people", base: "1f3a9", smile: "🎩" },
  { category: "people", base: "1f9e2", smile: "🧢" },
  { category: "people", base: "1f452", smile: "👒" },
  { category: "people", base: "1f393", smile: "🎓" },
  { category: "people", base: "26d1", smile: "⛑" },
  { category: "people", base: "1f451", smile: "👑" },
  { category: "people", base: "1f48d", smile: "💍" },
  { category: "people", base: "1f45d", smile: "👝" },
  { category: "people", base: "1f45b", smile: "👛" },
  { category: "people", base: "1f45c", smile: "👜" },
  { category: "people", base: "1f4bc", smile: "💼" },
  { category: "people", base: "1f392", smile: "🎒" },
  { category: "people", base: "1f9f3", smile: "🧳" },
  { category: "people", base: "1f453", smile: "👓" },
  { category: "people", base: "1f576", smile: "🕶" },
  { category: "people", base: "1f97d", smile: "🥽" },
  { category: "people", base: "1f302", smile: "🌂" },

  { category: "nature", base: "1f436", smile: "🐶" },
  { category: "nature", base: "1f431", smile: "🐱" },
  { category: "nature", base: "1f42d", smile: "🐭" },
  { category: "nature", base: "1f439", smile: "🐹" },
  { category: "nature", base: "1f430", smile: "🐰" },
  { category: "nature", base: "1f98a", smile: "🦊" },
  { category: "nature", base: "1f43b", smile: "🐻" },
  { category: "nature", base: "1f43c", smile: "🐼" },
  { category: "nature", base: "1f428", smile: "🐨" },
  { category: "nature", base: "1f42f", smile: "🐯" },
  { category: "nature", base: "1f981", smile: "🦁" },
  { category: "nature", base: "1f42e", smile: "🐮" },
  { category: "nature", base: "1f437", smile: "🐷" },
  { category: "nature", base: "1f43d", smile: "🐽" },
  { category: "nature", base: "1f438", smile: "🐸" },
  { category: "nature", base: "1f435", smile: "🐵" },
  { category: "nature", base: "1f648", smile: "🙈" },
  { category: "nature", base: "1f649", smile: "🙉" },
  { category: "nature", base: "1f64a", smile: "🙊" },
  { category: "nature", base: "1f412", smile: "🐒" },
  { category: "nature", base: "1f414", smile: "🐔" },
  { category: "nature", base: "1f427", smile: "🐧" },
  { category: "nature", base: "1f426", smile: "🐦" },
  { category: "nature", base: "1f424", smile: "🐤" },
  { category: "nature", base: "1f423", smile: "🐣" },
  { category: "nature", base: "1f425", smile: "🐥" },
  { category: "nature", base: "1f986", smile: "🦆" },
  { category: "nature", base: "1f985", smile: "🦅" },
  { category: "nature", base: "1f989", smile: "🦉" },
  { category: "nature", base: "1f987", smile: "🦇" },
  { category: "nature", base: "1f43a", smile: "🐺" },
  { category: "nature", base: "1f417", smile: "🐗" },
  { category: "nature", base: "1f434", smile: "🐴" },
  { category: "nature", base: "1f984", smile: "🦄" },
  { category: "nature", base: "1f41d", smile: "🐝" },
  { category: "nature", base: "1f41b", smile: "🐛" },
  { category: "nature", base: "1f98b", smile: "🦋" },
  { category: "nature", base: "1f40c", smile: "🐌" },
  { category: "nature", base: "1f41c", smile: "🐜" },
  { category: "nature", base: "1f99f", smile: "🦟" },
  { category: "nature", base: "1f997", smile: "🦗" },
  { category: "nature", base: "1f577", smile: "🕷" },
  { category: "nature", base: "1f578", smile: "🕸" },
  { category: "nature", base: "1f982", smile: "🦂" },
  { category: "nature", base: "1f422", smile: "🐢" },
  { category: "nature", base: "1f40d", smile: "🐍" },
  { category: "nature", base: "1f98e", smile: "🦎" },
  { category: "nature", base: "1f996", smile: "🦖" },
  { category: "nature", base: "1f995", smile: "🦕" },
  { category: "nature", base: "1f419", smile: "🐙" },
  { category: "nature", base: "1f991", smile: "🦑" },
  { category: "nature", base: "1f990", smile: "🦐" },
  { category: "nature", base: "1f99e", smile: "🦞" },
  { category: "nature", base: "1f980", smile: "🦀" },
  { category: "nature", base: "1f421", smile: "🐡" },
  { category: "nature", base: "1f420", smile: "🐠" },
  { category: "nature", base: "1f41f", smile: "🐟" },
  { category: "nature", base: "1f42c", smile: "🐬" },
  { category: "nature", base: "1f433", smile: "🐳" },
  { category: "nature", base: "1f40b", smile: "🐋" },
  { category: "nature", base: "1f988", smile: "🦈" },
  { category: "nature", base: "1f40a", smile: "🐊" },
  { category: "nature", base: "1f405", smile: "🐅" },
  { category: "nature", base: "1f406", smile: "🐆" },
  { category: "nature", base: "1f993", smile: "🦓" },
  { category: "nature", base: "1f98d", smile: "🦍" },
  { category: "nature", base: "1f418", smile: "🐘" },
  { category: "nature", base: "1f99b", smile: "🦛" },
  { category: "nature", base: "1f98f", smile: "🦏" },
  { category: "nature", base: "1f42a", smile: "🐪" },
  { category: "nature", base: "1f42b", smile: "🐫" },
  { category: "nature", base: "1f992", smile: "🦒" },
  { category: "nature", base: "1f998", smile: "🦘" },
  { category: "nature", base: "1f403", smile: "🐃" },
  { category: "nature", base: "1f402", smile: "🐂" },
  { category: "nature", base: "1f404", smile: "🐄" },
  { category: "nature", base: "1f40e", smile: "🐎" },
  { category: "nature", base: "1f416", smile: "🐖" },
  { category: "nature", base: "1f40f", smile: "🐏" },
  { category: "nature", base: "1f411", smile: "🐑" },
  { category: "nature", base: "1f999", smile: "🦙" },
  { category: "nature", base: "1f410", smile: "🐐" },
  { category: "nature", base: "1f98c", smile: "🦌" },
  { category: "nature", base: "1f415", smile: "🐕" },
  { category: "nature", base: "1f429", smile: "🐩" },
  { category: "nature", base: "1f408", smile: "🐈" },
  { category: "nature", base: "1f413", smile: "🐓" },
  { category: "nature", base: "1f983", smile: "🦃" },
  { category: "nature", base: "1f99a", smile: "🦚" },
  { category: "nature", base: "1f99c", smile: "🦜" },
  { category: "nature", base: "1f9a2", smile: "🦢" },
  { category: "nature", base: "1f54a", smile: "🕊" },
  { category: "nature", base: "1f407", smile: "🐇" },
  { category: "nature", base: "1f99d", smile: "🦝" },
  { category: "nature", base: "1f9a1", smile: "🦡" },
  { category: "nature", base: "1f401", smile: "🐁" },
  { category: "nature", base: "1f400", smile: "🐀" },
  { category: "nature", base: "1f43f", smile: "🐿" },
  { category: "nature", base: "1f994", smile: "🦔" },
  { category: "nature", base: "1f43e", smile: "🐾" },
  { category: "nature", base: "1f409", smile: "🐉" },
  { category: "nature", base: "1f432", smile: "🐲" },
  { category: "nature", base: "1f335", smile: "🌵" },
  { category: "nature", base: "1f384", smile: "🎄" },
  { category: "nature", base: "1f332", smile: "🌲" },
  { category: "nature", base: "1f333", smile: "🌳" },
  { category: "nature", base: "1f334", smile: "🌴" },
  { category: "nature", base: "1f331", smile: "🌱" },
  { category: "nature", base: "1f33f", smile: "🌿" },
  { category: "nature", base: "2618", smile: "☘" },
  { category: "nature", base: "1f340", smile: "🍀" },
  { category: "nature", base: "1f38d", smile: "🎍" },
  { category: "nature", base: "1f38b", smile: "🎋" },
  { category: "nature", base: "1f343", smile: "🍃" },
  { category: "nature", base: "1f342", smile: "🍂" },
  { category: "nature", base: "1f341", smile: "🍁" },
  { category: "nature", base: "1f344", smile: "🍄" },
  { category: "nature", base: "1f41a", smile: "🐚" },
  { category: "nature", base: "1f33e", smile: "🌾" },
  { category: "nature", base: "1f490", smile: "💐" },
  { category: "nature", base: "1f337", smile: "🌷" },
  { category: "nature", base: "1f339", smile: "🌹" },
  { category: "nature", base: "1f940", smile: "🥀" },
  { category: "nature", base: "1f33a", smile: "🌺" },
  { category: "nature", base: "1f338", smile: "🌸" },
  { category: "nature", base: "1f33c", smile: "🌼" },
  { category: "nature", base: "1f33b", smile: "🌻" },
  { category: "nature", base: "1f31e", smile: "🌞" },
  { category: "nature", base: "1f31d", smile: "🌝" },
  { category: "nature", base: "1f31b", smile: "🌛" },
  { category: "nature", base: "1f31c", smile: "🌜" },
  { category: "nature", base: "1f31a", smile: "🌚" },
  { category: "nature", base: "1f315", smile: "🌕" },
  { category: "nature", base: "1f316", smile: "🌖" },
  { category: "nature", base: "1f317", smile: "🌗" },
  { category: "nature", base: "1f318", smile: "🌘" },
  { category: "nature", base: "1f311", smile: "🌑" },
  { category: "nature", base: "1f312", smile: "🌒" },
  { category: "nature", base: "1f313", smile: "🌓" },
  { category: "nature", base: "1f314", smile: "🌔" },
  { category: "nature", base: "1f319", smile: "🌙" },
  { category: "nature", base: "1f30e", smile: "🌎" },
  { category: "nature", base: "1f30d", smile: "🌍" },
  { category: "nature", base: "1f30f", smile: "🌏" },
  { category: "nature", base: "1f4ab", smile: "💫" },
  { category: "nature", base: "2b50", smile: "⭐" },
  { category: "nature", base: "1f31f", smile: "🌟" },
  { category: "nature", base: "2728", smile: "✨" },
  { category: "nature", base: "26a1", smile: "⚡" },
  { category: "nature", base: "2604", smile: "☄" },
  { category: "nature", base: "1f4a5", smile: "💥" },
  { category: "nature", base: "1f525", smile: "🔥" },
  { category: "nature", base: "1f32a", smile: "🌪" },
  { category: "nature", base: "1f308", smile: "🌈" },
  { category: "nature", base: "2600", smile: "☀" },
  { category: "nature", base: "1f324", smile: "🌤" },
  { category: "nature", base: "26c5", smile: "⛅" },
  { category: "nature", base: "1f325", smile: "🌥" },
  { category: "nature", base: "2601", smile: "☁" },
  { category: "nature", base: "1f326", smile: "🌦" },
  { category: "nature", base: "1f327", smile: "🌧" },
  { category: "nature", base: "26c8", smile: "⛈" },
  { category: "nature", base: "1f329", smile: "🌩" },
  { category: "nature", base: "1f328", smile: "🌨" },
  { category: "nature", base: "2744", smile: "❄" },
  { category: "nature", base: "2603", smile: "☃" },
  { category: "nature", base: "26c4", smile: "⛄" },
  { category: "nature", base: "1f32c", smile: "🌬" },
  { category: "nature", base: "1f4a8", smile: "💨" },
  { category: "nature", base: "1f4a7", smile: "💧" },
  { category: "nature", base: "1f4a6", smile: "💦" },
  { category: "nature", base: "2614", smile: "☔" },
  { category: "nature", base: "2602", smile: "☂" },
  { category: "nature", base: "1f30a", smile: "🌊" },
  { category: "nature", base: "1f32b", smile: "🌫" },

  { category: "food", base: "1f34f", smile: "🍏" },
  { category: "food", base: "1f34e", smile: "🍎" },
  { category: "food", base: "1f350", smile: "🍐" },
  { category: "food", base: "1f34a", smile: "🍊" },
  { category: "food", base: "1f34b", smile: "🍋" },
  { category: "food", base: "1f34c", smile: "🍌" },
  { category: "food", base: "1f349", smile: "🍉" },
  { category: "food", base: "1f347", smile: "🍇" },
  { category: "food", base: "1f353", smile: "🍓" },
  { category: "food", base: "1f348", smile: "🍈" },
  { category: "food", base: "1f352", smile: "🍒" },
  { category: "food", base: "1f351", smile: "🍑" },
  { category: "food", base: "1f96d", smile: "🥭" },
  { category: "food", base: "1f34d", smile: "🍍" },
  { category: "food", base: "1f965", smile: "🥥" },
  { category: "food", base: "1f95d", smile: "🥝" },
  { category: "food", base: "1f345", smile: "🍅" },
  { category: "food", base: "1f346", smile: "🍆" },
  { category: "food", base: "1f951", smile: "🥑" },
  { category: "food", base: "1f966", smile: "🥦" },
  { category: "food", base: "1f96c", smile: "🥬" },
  { category: "food", base: "1f952", smile: "🥒" },
  { category: "food", base: "1f336", smile: "🌶" },
  { category: "food", base: "1f33d", smile: "🌽" },
  { category: "food", base: "1f955", smile: "🥕" },
  { category: "food", base: "1f954", smile: "🥔" },
  { category: "food", base: "1f360", smile: "🍠" },
  { category: "food", base: "1f950", smile: "🥐" },
  { category: "food", base: "1f96f", smile: "🥯" },
  { category: "food", base: "1f35e", smile: "🍞" },
  { category: "food", base: "1f956", smile: "🥖" },
  { category: "food", base: "1f968", smile: "🥨" },
  { category: "food", base: "1f9c0", smile: "🧀" },
  { category: "food", base: "1f95a", smile: "🥚" },
  { category: "food", base: "1f373", smile: "🍳" },
  { category: "food", base: "1f95e", smile: "🥞" },
  { category: "food", base: "1f953", smile: "🥓" },
  { category: "food", base: "1f969", smile: "🥩" },
  { category: "food", base: "1f357", smile: "🍗" },
  { category: "food", base: "1f356", smile: "🍖" },
  { category: "food", base: "1f32d", smile: "🌭" },
  { category: "food", base: "1f354", smile: "🍔" },
  { category: "food", base: "1f35f", smile: "🍟" },
  { category: "food", base: "1f355", smile: "🍕" },
  { category: "food", base: "1f96a", smile: "🥪" },
  { category: "food", base: "1f959", smile: "🥙" },
  { category: "food", base: "1f32e", smile: "🌮" },
  { category: "food", base: "1f32f", smile: "🌯" },
  { category: "food", base: "1f957", smile: "🥗" },
  { category: "food", base: "1f958", smile: "🥘" },
  { category: "food", base: "1f96b", smile: "🥫" },
  { category: "food", base: "1f35d", smile: "🍝" },
  { category: "food", base: "1f35c", smile: "🍜" },
  { category: "food", base: "1f372", smile: "🍲" },
  { category: "food", base: "1f35b", smile: "🍛" },
  { category: "food", base: "1f363", smile: "🍣" },
  { category: "food", base: "1f371", smile: "🍱" },
  { category: "food", base: "1f95f", smile: "🥟" },
  { category: "food", base: "1f364", smile: "🍤" },
  { category: "food", base: "1f359", smile: "🍙" },
  { category: "food", base: "1f35a", smile: "🍚" },
  { category: "food", base: "1f358", smile: "🍘" },
  { category: "food", base: "1f365", smile: "🍥" },
  { category: "food", base: "1f960", smile: "🥠" },
  { category: "food", base: "1f96e", smile: "🥮" },
  { category: "food", base: "1f362", smile: "🍢" },
  { category: "food", base: "1f361", smile: "🍡" },
  { category: "food", base: "1f367", smile: "🍧" },
  { category: "food", base: "1f368", smile: "🍨" },
  { category: "food", base: "1f366", smile: "🍦" },
  { category: "food", base: "1f967", smile: "🥧" },
  { category: "food", base: "1f9c1", smile: "🧁" },
  { category: "food", base: "1f370", smile: "🍰" },
  { category: "food", base: "1f382", smile: "🎂" },
  { category: "food", base: "1f36e", smile: "🍮" },
  { category: "food", base: "1f36d", smile: "🍭" },
  { category: "food", base: "1f36c", smile: "🍬" },
  { category: "food", base: "1f36b", smile: "🍫" },
  { category: "food", base: "1f37f", smile: "🍿" },
  { category: "food", base: "1f369", smile: "🍩" },
  { category: "food", base: "1f36a", smile: "🍪" },
  { category: "food", base: "1f330", smile: "🌰" },
  { category: "food", base: "1f95c", smile: "🥜" },
  { category: "food", base: "1f36f", smile: "🍯" },
  { category: "food", base: "1f95b", smile: "🥛" },
  { category: "food", base: "1f37c", smile: "🍼" },
  { category: "food", base: "2615", smile: "☕" },
  { category: "food", base: "1f375", smile: "🍵" },
  { category: "food", base: "1f964", smile: "🥤" },
  { category: "food", base: "1f376", smile: "🍶" },
  { category: "food", base: "1f37a", smile: "🍺" },
  { category: "food", base: "1f37b", smile: "🍻" },
  { category: "food", base: "1f942", smile: "🥂" },
  { category: "food", base: "1f377", smile: "🍷" },
  { category: "food", base: "1f943", smile: "🥃" },
  { category: "food", base: "1f378", smile: "🍸" },
  { category: "food", base: "1f379", smile: "🍹" },
  { category: "food", base: "1f37e", smile: "🍾" },
  { category: "food", base: "1f944", smile: "🥄" },
  { category: "food", base: "1f374", smile: "🍴" },
  { category: "food", base: "1f37d", smile: "🍽" },
  { category: "food", base: "1f963", smile: "🥣" },
  { category: "food", base: "1f961", smile: "🥡" },
  { category: "food", base: "1f962", smile: "🥢" },
  { category: "food", base: "1f9c2", smile: "🧂" },

  { category: "activity", base: "26bd", smile: "⚽" },
  { category: "activity", base: "1f3c0", smile: "🏀" },
  { category: "activity", base: "1f3c8", smile: "🏈" },
  { category: "activity", base: "26be", smile: "⚾" },
  { category: "activity", base: "1f94e", smile: "🥎" },
  { category: "activity", base: "1f3be", smile: "🎾" },
  { category: "activity", base: "1f3d0", smile: "🏐" },
  { category: "activity", base: "1f3c9", smile: "🏉" },
  { category: "activity", base: "1f94f", smile: "🥏" },
  { category: "activity", base: "1f3b1", smile: "🎱" },
  { category: "activity", base: "1f3d3", smile: "🏓" },
  { category: "activity", base: "1f3f8", smile: "🏸" },
  { category: "activity", base: "1f3d2", smile: "🏒" },
  { category: "activity", base: "1f3d1", smile: "🏑" },
  { category: "activity", base: "1f94d", smile: "🥍" },
  { category: "activity", base: "1f3cf", smile: "🏏" },
  { category: "activity", base: "1f945", smile: "🥅" },
  { category: "activity", base: "26f3", smile: "⛳" },
  { category: "activity", base: "1f3f9", smile: "🏹" },
  { category: "activity", base: "1f3a3", smile: "🎣" },
  { category: "activity", base: "1f94a", smile: "🥊" },
  { category: "activity", base: "1f94b", smile: "🥋" },
  { category: "activity", base: "1f3bd", smile: "🎽" },
  { category: "activity", base: "1f6f9", smile: "🛹" },
  { category: "activity", base: "1f6f7", smile: "🛷" },
  { category: "activity", base: "26f8", smile: "⛸" },
  { category: "activity", base: "1f94c", smile: "🥌" },
  { category: "activity", base: "1f3bf", smile: "🎿" },
  { category: "activity", base: "26f7", smile: "⛷" },
  { category: "activity", base: "1f3c2", smile: "🏂" },
  { category: "activity", base: "1f3cb", smile: "🏋" },
  { category: "activity", base: "1f93c", smile: "🤼" },
  { category: "activity", base: "1f938", smile: "🤸" },
  { category: "activity", base: "26f9", smile: "⛹" },
  { category: "activity", base: "1f93a", smile: "🤺" },
  { category: "activity", base: "1f93e", smile: "🤾" },
  { category: "activity", base: "1f3cc", smile: "🏌" },
  { category: "activity", base: "1f3c7", smile: "🏇" },
  { category: "activity", base: "1f9d8", smile: "🧘" },
  { category: "activity", base: "1f3c4", smile: "🏄" },
  { category: "activity", base: "1f3ca", smile: "🏊" },
  { category: "activity", base: "1f93d", smile: "🤽" },
  { category: "activity", base: "1f6a3", smile: "🚣" },
  { category: "activity", base: "1f9d7", smile: "🧗" },
  { category: "activity", base: "1f6b5", smile: "🚵" },
  { category: "activity", base: "1f6b4", smile: "🚴" },
  { category: "activity", base: "1f3c6", smile: "🏆" },
  { category: "activity", base: "1f947", smile: "🥇" },
  { category: "activity", base: "1f948", smile: "🥈" },
  { category: "activity", base: "1f949", smile: "🥉" },
  { category: "activity", base: "1f3c5", smile: "🏅" },
  { category: "activity", base: "1f396", smile: "🎖" },
  { category: "activity", base: "1f3f5", smile: "🏵" },
  { category: "activity", base: "1f397", smile: "🎗" },
  { category: "activity", base: "1f3ab", smile: "🎫" },
  { category: "activity", base: "1f39f", smile: "🎟" },
  { category: "activity", base: "1f3aa", smile: "🎪" },
  { category: "activity", base: "1f939", smile: "🤹" },
  { category: "activity", base: "1f3ad", smile: "🎭" },
  { category: "activity", base: "1f3a8", smile: "🎨" },
  { category: "activity", base: "1f3ac", smile: "🎬" },
  { category: "activity", base: "1f3a4", smile: "🎤" },
  { category: "activity", base: "1f3a7", smile: "🎧" },
  { category: "activity", base: "1f3bc", smile: "🎼" },
  { category: "activity", base: "1f3b9", smile: "🎹" },
  { category: "activity", base: "1f941", smile: "🥁" },
  { category: "activity", base: "1f3b7", smile: "🎷" },
  { category: "activity", base: "1f3ba", smile: "🎺" },
  { category: "activity", base: "1f3b8", smile: "🎸" },
  { category: "activity", base: "1f3bb", smile: "🎻" },
  { category: "activity", base: "1f3b2", smile: "🎲" },
  { category: "activity", base: "1f3af", smile: "🎯" },
  { category: "activity", base: "1f3b3", smile: "🎳" },
  { category: "activity", base: "1f3ae", smile: "🎮" },
  { category: "activity", base: "1f3b0", smile: "🎰" },
  { category: "activity", base: "1f9e9", smile: "🧩" },

  { category: "travel", base: "1f697", smile: "🚗" },
  { category: "travel", base: "1f695", smile: "🚕" },
  { category: "travel", base: "1f699", smile: "🚙" },
  { category: "travel", base: "1f68c", smile: "🚌" },
  { category: "travel", base: "1f68e", smile: "🚎" },
  { category: "travel", base: "1f3ce", smile: "🏎" },
  { category: "travel", base: "1f693", smile: "🚓" },
  { category: "travel", base: "1f691", smile: "🚑" },
  { category: "travel", base: "1f692", smile: "🚒" },
  { category: "travel", base: "1f690", smile: "🚐" },
  { category: "travel", base: "1f69a", smile: "🚚" },
  { category: "travel", base: "1f69b", smile: "🚛" },
  { category: "travel", base: "1f69c", smile: "🚜" },
  { category: "travel", base: "1f6f4", smile: "🛴" },
  { category: "travel", base: "1f6b2", smile: "🚲" },
  { category: "travel", base: "1f6f5", smile: "🛵" },
  { category: "travel", base: "1f3cd", smile: "🏍" },
  { category: "travel", base: "1f6a8", smile: "🚨" },
  { category: "travel", base: "1f694", smile: "🚔" },
  { category: "travel", base: "1f68d", smile: "🚍" },
  { category: "travel", base: "1f698", smile: "🚘" },
  { category: "travel", base: "1f696", smile: "🚖" },
  { category: "travel", base: "1f6a1", smile: "🚡" },
  { category: "travel", base: "1f6a0", smile: "🚠" },
  { category: "travel", base: "1f69f", smile: "🚟" },
  { category: "travel", base: "1f683", smile: "🚃" },
  { category: "travel", base: "1f68b", smile: "🚋" },
  { category: "travel", base: "1f69e", smile: "🚞" },
  { category: "travel", base: "1f69d", smile: "🚝" },
  { category: "travel", base: "1f684", smile: "🚄" },
  { category: "travel", base: "1f685", smile: "🚅" },
  { category: "travel", base: "1f688", smile: "🚈" },
  { category: "travel", base: "1f682", smile: "🚂" },
  { category: "travel", base: "1f686", smile: "🚆" },
  { category: "travel", base: "1f687", smile: "🚇" },
  { category: "travel", base: "1f68a", smile: "🚊" },
  { category: "travel", base: "1f689", smile: "🚉" },
  { category: "travel", base: "2708", smile: "✈" },
  { category: "travel", base: "1f6eb", smile: "🛫" },
  { category: "travel", base: "1f6ec", smile: "🛬" },
  { category: "travel", base: "1f6e9", smile: "🛩" },
  { category: "travel", base: "1f4ba", smile: "💺" },
  { category: "travel", base: "1f6f0", smile: "🛰" },
  { category: "travel", base: "1f680", smile: "🚀" },
  { category: "travel", base: "1f6f8", smile: "🛸" },
  { category: "travel", base: "1f681", smile: "🚁" },
  { category: "travel", base: "1f6f6", smile: "🛶" },
  { category: "travel", base: "26f5", smile: "⛵" },
  { category: "travel", base: "1f6a4", smile: "🚤" },
  { category: "travel", base: "1f6e5", smile: "🛥" },
  { category: "travel", base: "1f6f3", smile: "🛳" },
  { category: "travel", base: "26f4", smile: "⛴" },
  { category: "travel", base: "1f6a2", smile: "🚢" },
  { category: "travel", base: "2693", smile: "⚓" },
  { category: "travel", base: "26fd", smile: "⛽" },
  { category: "travel", base: "1f6a7", smile: "🚧" },
  { category: "travel", base: "1f6a6", smile: "🚦" },
  { category: "travel", base: "1f6a5", smile: "🚥" },
  { category: "travel", base: "1f68f", smile: "🚏" },
  { category: "travel", base: "1f5fa", smile: "🗺" },
  { category: "travel", base: "1f5ff", smile: "🗿" },
  { category: "travel", base: "1f5fd", smile: "🗽" },
  { category: "travel", base: "1f5fc", smile: "🗼" },
  { category: "travel", base: "1f3f0", smile: "🏰" },
  { category: "travel", base: "1f3ef", smile: "🏯" },
  { category: "travel", base: "1f3df", smile: "🏟" },
  { category: "travel", base: "1f3a1", smile: "🎡" },
  { category: "travel", base: "1f3a2", smile: "🎢" },
  { category: "travel", base: "1f3a0", smile: "🎠" },
  { category: "travel", base: "26f2", smile: "⛲" },
  { category: "travel", base: "26f1", smile: "⛱" },
  { category: "travel", base: "1f3d6", smile: "🏖" },
  { category: "travel", base: "1f3dd", smile: "🏝" },
  { category: "travel", base: "1f3dc", smile: "🏜" },
  { category: "travel", base: "1f30b", smile: "🌋" },
  { category: "travel", base: "26f0", smile: "⛰" },
  { category: "travel", base: "1f3d4", smile: "🏔" },
  { category: "travel", base: "1f5fb", smile: "🗻" },
  { category: "travel", base: "1f3d5", smile: "🏕" },
  { category: "travel", base: "26fa", smile: "⛺" },
  { category: "travel", base: "1f3e0", smile: "🏠" },
  { category: "travel", base: "1f3e1", smile: "🏡" },
  { category: "travel", base: "1f3d8", smile: "🏘" },
  { category: "travel", base: "1f3da", smile: "🏚" },
  { category: "travel", base: "1f3d7", smile: "🏗" },
  { category: "travel", base: "1f3ed", smile: "🏭" },
  { category: "travel", base: "1f3e2", smile: "🏢" },
  { category: "travel", base: "1f3ec", smile: "🏬" },
  { category: "travel", base: "1f3e3", smile: "🏣" },
  { category: "travel", base: "1f3e4", smile: "🏤" },
  { category: "travel", base: "1f3e5", smile: "🏥" },
  { category: "travel", base: "1f3e6", smile: "🏦" },
  { category: "travel", base: "1f3e8", smile: "🏨" },
  { category: "travel", base: "1f3ea", smile: "🏪" },
  { category: "travel", base: "1f3eb", smile: "🏫" },
  { category: "travel", base: "1f3e9", smile: "🏩" },
  { category: "travel", base: "1f492", smile: "💒" },
  { category: "travel", base: "1f3db", smile: "🏛" },
  { category: "travel", base: "26ea", smile: "⛪" },
  { category: "travel", base: "1f54c", smile: "🕌" },
  { category: "travel", base: "1f54d", smile: "🕍" },
  { category: "travel", base: "1f54b", smile: "🕋" },
  { category: "travel", base: "26e9", smile: "⛩" },
  { category: "travel", base: "1f6e4", smile: "🛤" },
  { category: "travel", base: "1f6e3", smile: "🛣" },
  { category: "travel", base: "1f5fe", smile: "🗾" },
  { category: "travel", base: "1f391", smile: "🎑" },
  { category: "travel", base: "1f3de", smile: "🏞" },
  { category: "travel", base: "1f305", smile: "🌅" },
  { category: "travel", base: "1f304", smile: "🌄" },
  { category: "travel", base: "1f320", smile: "🌠" },
  { category: "travel", base: "1f387", smile: "🎇" },
  { category: "travel", base: "1f386", smile: "🎆" },
  { category: "travel", base: "1f307", smile: "🌇" },
  { category: "travel", base: "1f306", smile: "🌆" },
  { category: "travel", base: "1f3d9", smile: "🏙" },
  { category: "travel", base: "1f303", smile: "🌃" },
  { category: "travel", base: "1f30c", smile: "🌌" },
  { category: "travel", base: "1f309", smile: "🌉" },
  { category: "travel", base: "1f301", smile: "🌁" },

  { category: "objects", base: "231a", smile: "⌚" },
  { category: "objects", base: "1f4f2", smile: "📲" },
  { category: "objects", base: "1f4bb", smile: "💻" },
  { category: "objects", base: "2328", smile: "⌨" },
  { category: "objects", base: "1f5a5", smile: "🖥" },
  { category: "objects", base: "1f5a8", smile: "🖨" },
  { category: "objects", base: "1f5b1", smile: "🖱" },
  { category: "objects", base: "1f5b2", smile: "🖲" },
  { category: "objects", base: "1f579", smile: "🕹" },
  { category: "objects", base: "1f5dc", smile: "🗜" },
  { category: "objects", base: "1f4bd", smile: "💽" },
  { category: "objects", base: "1f4be", smile: "💾" },
  { category: "objects", base: "1f4bf", smile: "💿" },
  { category: "objects", base: "1f4c0", smile: "📀" },
  { category: "objects", base: "1f4fc", smile: "📼" },
  { category: "objects", base: "1f4f7", smile: "📷" },
  { category: "objects", base: "1f4f8", smile: "📸" },
  { category: "objects", base: "1f4f9", smile: "📹" },
  { category: "objects", base: "1f3a5", smile: "🎥" },
  { category: "objects", base: "1f4fd", smile: "📽" },
  { category: "objects", base: "1f39e", smile: "🎞" },
  { category: "objects", base: "1f4de", smile: "📞" },
  { category: "objects", base: "260e", smile: "☎" },
  { category: "objects", base: "1f4df", smile: "📟" },
  { category: "objects", base: "1f4e0", smile: "📠" },
  { category: "objects", base: "1f4fa", smile: "📺" },
  { category: "objects", base: "1f4fb", smile: "📻" },
  { category: "objects", base: "1f399", smile: "🎙" },
  { category: "objects", base: "1f39a", smile: "🎚" },
  { category: "objects", base: "1f39b", smile: "🎛" },
  { category: "objects", base: "1f9ed", smile: "🧭" },
  { category: "objects", base: "23f1", smile: "⏱" },
  { category: "objects", base: "23f2", smile: "⏲" },
  { category: "objects", base: "23f0", smile: "⏰" },
  { category: "objects", base: "1f570", smile: "🕰" },
  { category: "objects", base: "231b", smile: "⌛" },
  { category: "objects", base: "23f3", smile: "⏳" },
  { category: "objects", base: "1f4e1", smile: "📡" },
  { category: "objects", base: "1f50b", smile: "🔋" },
  { category: "objects", base: "1f50c", smile: "🔌" },
  { category: "objects", base: "1f4a1", smile: "💡" },
  { category: "objects", base: "1f526", smile: "🔦" },
  { category: "objects", base: "1f56f", smile: "🕯" },
  { category: "objects", base: "1f9ef", smile: "🧯" },
  { category: "objects", base: "1f6e2", smile: "🛢" },
  { category: "objects", base: "1f4b8", smile: "💸" },
  { category: "objects", base: "1f4b5", smile: "💵" },
  { category: "objects", base: "1f4b4", smile: "💴" },
  { category: "objects", base: "1f4b6", smile: "💶" },
  { category: "objects", base: "1f4b7", smile: "💷" },
  { category: "objects", base: "1f4b0", smile: "💰" },
  { category: "objects", base: "1f4b3", smile: "💳" },
  { category: "objects", base: "1f48e", smile: "💎" },
  { category: "objects", base: "2696", smile: "⚖" },
  { category: "objects", base: "1f9f0", smile: "🧰" },
  { category: "objects", base: "1f527", smile: "🔧" },
  { category: "objects", base: "1f528", smile: "🔨" },
  { category: "objects", base: "2692", smile: "⚒" },
  { category: "objects", base: "1f6e0", smile: "🛠" },
  { category: "objects", base: "26cf", smile: "⛏" },
  { category: "objects", base: "1f529", smile: "🔩" },
  { category: "objects", base: "2699", smile: "⚙" },
  { category: "objects", base: "1f9f1", smile: "🧱" },
  { category: "objects", base: "26d3", smile: "⛓" },
  { category: "objects", base: "1f9f2", smile: "🧲" },
  { category: "objects", base: "1f52b", smile: "🔫" },
  { category: "objects", base: "1f4a3", smile: "💣" },
  { category: "objects", base: "1f9e8", smile: "🧨" },
  { category: "objects", base: "1f52a", smile: "🔪" },
  { category: "objects", base: "1f5e1", smile: "🗡" },
  { category: "objects", base: "2694", smile: "⚔" },
  { category: "objects", base: "1f6e1", smile: "🛡" },
  { category: "objects", base: "1f6ac", smile: "🚬" },
  { category: "objects", base: "26b0", smile: "⚰" },
  { category: "objects", base: "26b1", smile: "⚱" },
  { category: "objects", base: "1f3fa", smile: "🏺" },
  { category: "objects", base: "1f52e", smile: "🔮" },
  { category: "objects", base: "1f4ff", smile: "📿" },
  { category: "objects", base: "1f9ff", smile: "🧿" },
  { category: "objects", base: "1f488", smile: "💈" },
  { category: "objects", base: "2697", smile: "⚗" },
  { category: "objects", base: "1f52d", smile: "🔭" },
  { category: "objects", base: "1f52c", smile: "🔬" },
  { category: "objects", base: "1f573", smile: "🕳" },
  { category: "objects", base: "1f48a", smile: "💊" },
  { category: "objects", base: "1f489", smile: "💉" },
  { category: "objects", base: "1f9ec", smile: "🧬" },
  { category: "objects", base: "1f9a0", smile: "🦠" },
  { category: "objects", base: "1f9eb", smile: "🧫" },
  { category: "objects", base: "1f9ea", smile: "🧪" },
  { category: "objects", base: "1f321", smile: "🌡" },
  { category: "objects", base: "1f9f9", smile: "🧹" },
  { category: "objects", base: "1f9fa", smile: "🧺" },
  { category: "objects", base: "1f9fb", smile: "🧻" },
  { category: "objects", base: "1f6bd", smile: "🚽" },
  { category: "objects", base: "1f6b0", smile: "🚰" },
  { category: "objects", base: "1f6bf", smile: "🚿" },
  { category: "objects", base: "1f6c1", smile: "🛁" },
  { category: "objects", base: "1f6c0", smile: "🛀" },
  { category: "objects", base: "1f9fc", smile: "🧼" },
  { category: "objects", base: "1f9fd", smile: "🧽" },
  { category: "objects", base: "1f9f4", smile: "🧴" },
  { category: "objects", base: "1f6ce", smile: "🛎" },
  { category: "objects", base: "1f511", smile: "🔑" },
  { category: "objects", base: "1f5dd", smile: "🗝" },
  { category: "objects", base: "1f6aa", smile: "🚪" },
  { category: "objects", base: "1f6cb", smile: "🛋" },
  { category: "objects", base: "1f6cf", smile: "🛏" },
  { category: "objects", base: "1f6cc", smile: "🛌" },
  { category: "objects", base: "1f9f8", smile: "🧸" },
  { category: "objects", base: "1f5bc", smile: "🖼" },
  { category: "objects", base: "1f6cd", smile: "🛍" },
  { category: "objects", base: "1f6d2", smile: "🛒" },
  { category: "objects", base: "1f381", smile: "🎁" },
  { category: "objects", base: "1f388", smile: "🎈" },
  { category: "objects", base: "1f38f", smile: "🎏" },
  { category: "objects", base: "1f380", smile: "🎀" },
  { category: "objects", base: "1f38a", smile: "🎊" },
  { category: "objects", base: "1f389", smile: "🎉" },
  { category: "objects", base: "1f38e", smile: "🎎" },
  { category: "objects", base: "1f3ee", smile: "🏮" },
  { category: "objects", base: "1f390", smile: "🎐" },
  { category: "objects", base: "1f9e7", smile: "🧧" },
  { category: "objects", base: "2709", smile: "✉" },
  { category: "objects", base: "1f4e9", smile: "📩" },
  { category: "objects", base: "1f4e8", smile: "📨" },
  { category: "objects", base: "1f4e7", smile: "📧" },
  { category: "objects", base: "1f48c", smile: "💌" },
  { category: "objects", base: "1f4e5", smile: "📥" },
  { category: "objects", base: "1f4e4", smile: "📤" },
  { category: "objects", base: "1f4e6", smile: "📦" },
  { category: "objects", base: "1f3f7", smile: "🏷" },
  { category: "objects", base: "1f4ea", smile: "📪" },
  { category: "objects", base: "1f4eb", smile: "📫" },
  { category: "objects", base: "1f4ec", smile: "📬" },
  { category: "objects", base: "1f4ed", smile: "📭" },
  { category: "objects", base: "1f4ee", smile: "📮" },
  { category: "objects", base: "1f4ef", smile: "📯" },
  { category: "objects", base: "1f4dc", smile: "📜" },
  { category: "objects", base: "1f4c3", smile: "📃" },
  { category: "objects", base: "1f4c4", smile: "📄" },
  { category: "objects", base: "1f4d1", smile: "📑" },
  { category: "objects", base: "1f9fe", smile: "🧾" },
  { category: "objects", base: "1f4ca", smile: "📊" },
  { category: "objects", base: "1f4c8", smile: "📈" },
  { category: "objects", base: "1f4c9", smile: "📉" },
  { category: "objects", base: "1f5d2", smile: "🗒" },
  { category: "objects", base: "1f5d3", smile: "🗓" },
  { category: "objects", base: "1f4c6", smile: "📆" },
  { category: "objects", base: "1f4c5", smile: "📅" },
  { category: "objects", base: "1f5d1", smile: "🗑" },
  { category: "objects", base: "1f4c7", smile: "📇" },
  { category: "objects", base: "1f5c3", smile: "🗃" },
  { category: "objects", base: "1f5f3", smile: "🗳" },
  { category: "objects", base: "1f5c4", smile: "🗄" },
  { category: "objects", base: "1f4cb", smile: "📋" },
  { category: "objects", base: "1f4c1", smile: "📁" },
  { category: "objects", base: "1f4c2", smile: "📂" },
  { category: "objects", base: "1f5c2", smile: "🗂" },
  { category: "objects", base: "1f5de", smile: "🗞" },
  { category: "objects", base: "1f4f0", smile: "📰" },
  { category: "objects", base: "1f4d3", smile: "📓" },
  { category: "objects", base: "1f4d4", smile: "📔" },
  { category: "objects", base: "1f4d2", smile: "📒" },
  { category: "objects", base: "1f4d5", smile: "📕" },
  { category: "objects", base: "1f4d7", smile: "📗" },
  { category: "objects", base: "1f4d8", smile: "📘" },
  { category: "objects", base: "1f4d9", smile: "📙" },
  { category: "objects", base: "1f4da", smile: "📚" },
  { category: "objects", base: "1f4d6", smile: "📖" },
  { category: "objects", base: "1f516", smile: "🔖" },
  { category: "objects", base: "1f9f7", smile: "🧷" },
  { category: "objects", base: "1f517", smile: "🔗" },
  { category: "objects", base: "1f4ce", smile: "📎" },
  { category: "objects", base: "1f587", smile: "🖇" },
  { category: "objects", base: "1f4d0", smile: "📐" },
  { category: "objects", base: "1f4cf", smile: "📏" },
  { category: "objects", base: "1f9ee", smile: "🧮" },
  { category: "objects", base: "1f4cc", smile: "📌" },
  { category: "objects", base: "1f4cd", smile: "📍" },
  { category: "objects", base: "2702", smile: "✂" },
  { category: "objects", base: "1f58a", smile: "🖊" },
  { category: "objects", base: "1f58b", smile: "🖋" },
  { category: "objects", base: "2712", smile: "✒" },
  { category: "objects", base: "1f58c", smile: "🖌" },
  { category: "objects", base: "1f58d", smile: "🖍" },
  { category: "objects", base: "1f4dd", smile: "📝" },
  { category: "objects", base: "270f", smile: "✏" },
  { category: "objects", base: "1f50d", smile: "🔍" },
  { category: "objects", base: "1f50e", smile: "🔎" },
  { category: "objects", base: "1f50f", smile: "🔏" },
  { category: "objects", base: "1f510", smile: "🔐" },
  { category: "objects", base: "1f512", smile: "🔒" },
  { category: "objects", base: "1f513", smile: "🔓" },

  { category: "symbols", base: "2764", smile: "❤" },
  { category: "symbols", base: "1f9e1", smile: "🧡" },
  { category: "symbols", base: "1f49b", smile: "💛" },
  { category: "symbols", base: "1f49a", smile: "💚" },
  { category: "symbols", base: "1f499", smile: "💙" },
  { category: "symbols", base: "1f49c", smile: "💜" },
  { category: "symbols", base: "1f5a4", smile: "🖤" },
  { category: "symbols", base: "1f494", smile: "💔" },
  { category: "symbols", base: "2763", smile: "❣" },
  { category: "symbols", base: "1f495", smile: "💕" },
  { category: "symbols", base: "1f49e", smile: "💞" },
  { category: "symbols", base: "1f493", smile: "💓" },
  { category: "symbols", base: "1f497", smile: "💗" },
  { category: "symbols", base: "1f496", smile: "💖" },
  { category: "symbols", base: "1f498", smile: "💘" },
  { category: "symbols", base: "1f49d", smile: "💝" },
  { category: "symbols", base: "1f49f", smile: "💟" },
  { category: "symbols", base: "262e", smile: "☮" },
  { category: "symbols", base: "271d", smile: "✝" },
  { category: "symbols", base: "262a", smile: "☪" },
  { category: "symbols", base: "1f549", smile: "🕉" },
  { category: "symbols", base: "2638", smile: "☸" },
  { category: "symbols", base: "2721", smile: "✡" },
  { category: "symbols", base: "1f52f", smile: "🔯" },
  { category: "symbols", base: "1f54e", smile: "🕎" },
  { category: "symbols", base: "262f", smile: "☯" },
  { category: "symbols", base: "2626", smile: "☦" },
  { category: "symbols", base: "1f6d0", smile: "🛐" },
  { category: "symbols", base: "26ce", smile: "⛎" },
  { category: "symbols", base: "2648", smile: "♈" },
  { category: "symbols", base: "2649", smile: "♉" },
  { category: "symbols", base: "264a", smile: "♊" },
  { category: "symbols", base: "264b", smile: "♋" },
  { category: "symbols", base: "264c", smile: "♌" },
  { category: "symbols", base: "264d", smile: "♍" },
  { category: "symbols", base: "264e", smile: "♎" },
  { category: "symbols", base: "264f", smile: "♏" },
  { category: "symbols", base: "2650", smile: "♐" },
  { category: "symbols", base: "2651", smile: "♑" },
  { category: "symbols", base: "2652", smile: "♒" },
  { category: "symbols", base: "2653", smile: "♓" },
  { category: "symbols", base: "1f194", smile: "🆔" },
  { category: "symbols", base: "269b", smile: "⚛" },
  { category: "symbols", base: "1f251", smile: "🉑" },
  { category: "symbols", base: "2622", smile: "☢" },
  { category: "symbols", base: "2623", smile: "☣" },
  { category: "symbols", base: "1f4f4", smile: "📴" },
  { category: "symbols", base: "1f4f3", smile: "📳" },
  { category: "symbols", base: "1f236", smile: "🈶" },
  { category: "symbols", base: "1f21a", smile: "🈚" },
  { category: "symbols", base: "1f238", smile: "🈸" },
  { category: "symbols", base: "1f23a", smile: "🈺" },
  { category: "symbols", base: "1f237", smile: "🈷" },
  { category: "symbols", base: "2734", smile: "✴" },
  { category: "symbols", base: "1f19a", smile: "🆚" },
  { category: "symbols", base: "1f4ae", smile: "💮" },
  { category: "symbols", base: "1f250", smile: "🉐" },
  { category: "symbols", base: "3299", smile: "㊙" },
  { category: "symbols", base: "3297", smile: "㊗" },
  { category: "symbols", base: "1f234", smile: "🈴" },
  { category: "symbols", base: "1f235", smile: "🈵" },
  { category: "symbols", base: "1f239", smile: "🈹" },
  { category: "symbols", base: "1f232", smile: "🈲" },
  { category: "symbols", base: "1f170", smile: "🅰" },
  { category: "symbols", base: "1f171", smile: "🅱" },
  { category: "symbols", base: "1f18e", smile: "🆎" },
  { category: "symbols", base: "1f191", smile: "🆑" },
  { category: "symbols", base: "1f17e", smile: "🅾" },
  { category: "symbols", base: "1f198", smile: "🆘" },
  { category: "symbols", base: "274c", smile: "❌" },
  { category: "symbols", base: "2b55", smile: "⭕" },
  { category: "symbols", base: "1f6d1", smile: "🛑" },
  { category: "symbols", base: "26d4", smile: "⛔" },
  { category: "symbols", base: "1f4db", smile: "📛" },
  { category: "symbols", base: "1f6ab", smile: "🚫" },
  { category: "symbols", base: "1f4af", smile: "💯" },
  { category: "symbols", base: "1f4a2", smile: "💢" },
  { category: "symbols", base: "2668", smile: "♨" },
  { category: "symbols", base: "1f6b7", smile: "🚷" },
  { category: "symbols", base: "1f6af", smile: "🚯" },
  { category: "symbols", base: "1f6b3", smile: "🚳" },
  { category: "symbols", base: "1f6b1", smile: "🚱" },
  { category: "symbols", base: "1f51e", smile: "🔞" },
  { category: "symbols", base: "1f4f5", smile: "📵" },
  { category: "symbols", base: "1f6ad", smile: "🚭" },
  { category: "symbols", base: "2757", smile: "❗" },
  { category: "symbols", base: "2755", smile: "❕" },
  { category: "symbols", base: "2753", smile: "❓" },
  { category: "symbols", base: "2754", smile: "❔" },
  { category: "symbols", base: "203c", smile: "‼" },
  { category: "symbols", base: "2049", smile: "⁉" },
  { category: "symbols", base: "1f505", smile: "🔅" },
  { category: "symbols", base: "1f506", smile: "🔆" },
  { category: "symbols", base: "303d", smile: "〽" },
  { category: "symbols", base: "26a0", smile: "⚠" },
  { category: "symbols", base: "1f6b8", smile: "🚸" },
  { category: "symbols", base: "1f531", smile: "🔱" },
  { category: "symbols", base: "269c", smile: "⚜" },
  { category: "symbols", base: "1f530", smile: "🔰" },
  { category: "symbols", base: "267b", smile: "♻" },
  { category: "symbols", base: "2705", smile: "✅" },
  { category: "symbols", base: "1f22f", smile: "🈯" },
  { category: "symbols", base: "1f4b9", smile: "💹" },
  { category: "symbols", base: "2747", smile: "❇" },
  { category: "symbols", base: "2733", smile: "✳" },
  { category: "symbols", base: "274e", smile: "❎" },
  { category: "symbols", base: "1f310", smile: "🌐" },
  { category: "symbols", base: "1f4a0", smile: "💠" },
  { category: "symbols", base: "24c2", smile: "Ⓜ" },
  { category: "symbols", base: "1f300", smile: "🌀" },
  { category: "symbols", base: "1f4a4", smile: "💤" },
  { category: "symbols", base: "1f3e7", smile: "🏧" },
  { category: "symbols", base: "1f6be", smile: "🚾" },
  { category: "symbols", base: "267f", smile: "♿" },
  { category: "symbols", base: "1f17f", smile: "🅿" },
  { category: "symbols", base: "1f233", smile: "🈳" },
  { category: "symbols", base: "1f202", smile: "🈂" },
  { category: "symbols", base: "1f6c2", smile: "🛂" },
  { category: "symbols", base: "1f6c3", smile: "🛃" },
  { category: "symbols", base: "1f6c4", smile: "🛄" },
  { category: "symbols", base: "1f6c5", smile: "🛅" },
  { category: "symbols", base: "1f6b9", smile: "🚹" },
  { category: "symbols", base: "1f6ba", smile: "🚺" },
  { category: "symbols", base: "1f6bc", smile: "🚼" },
  { category: "symbols", base: "1f6bb", smile: "🚻" },
  { category: "symbols", base: "1f6ae", smile: "🚮" },
  { category: "symbols", base: "1f3a6", smile: "🎦" },
  { category: "symbols", base: "1f4f6", smile: "📶" },
  { category: "symbols", base: "1f201", smile: "🈁" },
  { category: "symbols", base: "1f523", smile: "🔣" },
  { category: "symbols", base: "2139", smile: "ℹ" },
  { category: "symbols", base: "1f524", smile: "🔤" },
  { category: "symbols", base: "1f521", smile: "🔡" },
  { category: "symbols", base: "1f520", smile: "🔠" },
  { category: "symbols", base: "1f196", smile: "🆖" },
  { category: "symbols", base: "1f197", smile: "🆗" },
  { category: "symbols", base: "1f199", smile: "🆙" },
  { category: "symbols", base: "1f192", smile: "🆒" },
  { category: "symbols", base: "1f195", smile: "🆕" },
  { category: "symbols", base: "1f193", smile: "🆓" },
  { category: "symbols", base: "1f51f", smile: "🔟" },
  { category: "symbols", base: "1f522", smile: "🔢" },
  { category: "symbols", base: "23cf", smile: "⏏" },
  { category: "symbols", base: "25b6", smile: "▶" },
  { category: "symbols", base: "23f8", smile: "⏸" },
  { category: "symbols", base: "23ef", smile: "⏯" },
  { category: "symbols", base: "23f9", smile: "⏹" },
  { category: "symbols", base: "23fa", smile: "⏺" },
  { category: "symbols", base: "23ed", smile: "⏭" },
  { category: "symbols", base: "23ee", smile: "⏮" },
  { category: "symbols", base: "23e9", smile: "⏩" },
  { category: "symbols", base: "23ea", smile: "⏪" },
  { category: "symbols", base: "23eb", smile: "⏫" },
  { category: "symbols", base: "23ec", smile: "⏬" },
  { category: "symbols", base: "25c0", smile: "◀" },
  { category: "symbols", base: "1f53c", smile: "🔼" },
  { category: "symbols", base: "1f53d", smile: "🔽" },
  { category: "symbols", base: "27a1", smile: "➡" },
  { category: "symbols", base: "2b05", smile: "⬅" },
  { category: "symbols", base: "2b06", smile: "⬆" },
  { category: "symbols", base: "2b07", smile: "⬇" },
  { category: "symbols", base: "2197", smile: "↗" },
  { category: "symbols", base: "2198", smile: "↘" },
  { category: "symbols", base: "2199", smile: "↙" },
  { category: "symbols", base: "2196", smile: "↖" },
  { category: "symbols", base: "2195", smile: "↕" },
  { category: "symbols", base: "2194", smile: "↔" },
  { category: "symbols", base: "21aa", smile: "↪" },
  { category: "symbols", base: "21a9", smile: "↩" },
  { category: "symbols", base: "2934", smile: "⤴" },
  { category: "symbols", base: "2935", smile: "⤵" },
  { category: "symbols", base: "1f500", smile: "🔀" },
  { category: "symbols", base: "1f501", smile: "🔁" },
  { category: "symbols", base: "1f502", smile: "🔂" },
  { category: "symbols", base: "1f504", smile: "🔄" },
  { category: "symbols", base: "1f503", smile: "🔃" },
  { category: "symbols", base: "1f3b5", smile: "🎵" },
  { category: "symbols", base: "1f3b6", smile: "🎶" },
  { category: "symbols", base: "2795", smile: "➕" },
  { category: "symbols", base: "2796", smile: "➖" },
  { category: "symbols", base: "2797", smile: "➗" },
  { category: "symbols", base: "2716", smile: "✖" },
  { category: "symbols", base: "267e", smile: "♾" },
  { category: "symbols", base: "1f4b2", smile: "💲" },
  { category: "symbols", base: "1f4b1", smile: "💱" },
  { category: "symbols", base: "3030", smile: "〰" },
  { category: "symbols", base: "27b0", smile: "➰" },
  { category: "symbols", base: "27bf", smile: "➿" },
  { category: "symbols", base: "1f51a", smile: "🔚" },
  { category: "symbols", base: "1f519", smile: "🔙" },
  { category: "symbols", base: "1f51b", smile: "🔛" },
  { category: "symbols", base: "1f51d", smile: "🔝" },
  { category: "symbols", base: "1f51c", smile: "🔜" },
  { category: "symbols", base: "2714", smile: "✔" },
  { category: "symbols", base: "2611", smile: "☑" },
  { category: "symbols", base: "1f518", smile: "🔘" },
  { category: "symbols", base: "26aa", smile: "⚪" },
  { category: "symbols", base: "26ab", smile: "⚫" },
  { category: "symbols", base: "1f534", smile: "🔴" },
  { category: "symbols", base: "1f535", smile: "🔵" },
  { category: "symbols", base: "1f53a", smile: "🔺" },
  { category: "symbols", base: "1f53b", smile: "🔻" },
  { category: "symbols", base: "1f538", smile: "🔸" },
  { category: "symbols", base: "1f539", smile: "🔹" },
  { category: "symbols", base: "1f536", smile: "🔶" },
  { category: "symbols", base: "1f537", smile: "🔷" },
  { category: "symbols", base: "1f533", smile: "🔳" },
  { category: "symbols", base: "1f532", smile: "🔲" },
  { category: "symbols", base: "25aa", smile: "▪" },
  { category: "symbols", base: "25ab", smile: "▫" },
  { category: "symbols", base: "25fe", smile: "◾" },
  { category: "symbols", base: "25fd", smile: "◽" },
  { category: "symbols", base: "25fc", smile: "◼" },
  { category: "symbols", base: "25fb", smile: "◻" },
  { category: "symbols", base: "2b1b", smile: "⬛" },
  { category: "symbols", base: "2b1c", smile: "⬜" },
  { category: "symbols", base: "1f508", smile: "🔈" },
  { category: "symbols", base: "1f507", smile: "🔇" },
  { category: "symbols", base: "1f509", smile: "🔉" },
  { category: "symbols", base: "1f50a", smile: "🔊" },
  { category: "symbols", base: "1f514", smile: "🔔" },
  { category: "symbols", base: "1f515", smile: "🔕" },
  { category: "symbols", base: "1f4e3", smile: "📣" },
  { category: "symbols", base: "1f4e2", smile: "📢" },
  { category: "symbols", base: "1f5e8", smile: "🗨" },
  { category: "symbols", base: "1f4ac", smile: "💬" },
  { category: "symbols", base: "1f4ad", smile: "💭" },
  { category: "symbols", base: "1f5ef", smile: "🗯" },
  { category: "symbols", base: "2660", smile: "♠" },
  { category: "symbols", base: "2663", smile: "♣" },
  { category: "symbols", base: "2665", smile: "♥" },
  { category: "symbols", base: "2666", smile: "♦" },
  { category: "symbols", base: "1f0cf", smile: "🃏" },
  { category: "symbols", base: "1f3b4", smile: "🎴" },
  { category: "symbols", base: "1f004", smile: "🀄" },

  { category: "symbols", base: "1f550", smile: "🕐" },
  { category: "symbols", base: "1f551", smile: "🕑" },
  { category: "symbols", base: "1f552", smile: "🕒" },
  { category: "symbols", base: "1f553", smile: "🕓" },
  { category: "symbols", base: "1f554", smile: "🕔" },
  { category: "symbols", base: "1f555", smile: "🕕" },
  { category: "symbols", base: "1f556", smile: "🕖" },
  { category: "symbols", base: "1f557", smile: "🕗" },
  { category: "symbols", base: "1f558", smile: "🕘" },
  { category: "symbols", base: "1f559", smile: "🕙" },
  { category: "symbols", base: "1f55a", smile: "🕚" },
  { category: "symbols", base: "1f55b", smile: "🕛" },
  { category: "symbols", base: "1f55c", smile: "🕜" },
  { category: "symbols", base: "1f55d", smile: "🕝" },
  { category: "symbols", base: "1f55e", smile: "🕞" },
  { category: "symbols", base: "1f55f", smile: "🕟" },
  { category: "symbols", base: "1f560", smile: "🕠" },
  { category: "symbols", base: "1f561", smile: "🕡" },
  { category: "symbols", base: "1f562", smile: "🕢" },
  { category: "symbols", base: "1f563", smile: "🕣" },
  { category: "symbols", base: "1f564", smile: "🕤" },
  { category: "symbols", base: "1f565", smile: "🕥" },
  { category: "symbols", base: "1f566", smile: "🕦" },
  { category: "symbols", base: "1f567", smile: "🕧" },
  { category: "symbols", base: "2640", smile: "♀" },
  { category: "symbols", base: "2642", smile: "♂" },
  { category: "symbols", base: "2695", smile: "⚕" },

  { category: "flags", base: "1f3f3", smile: "🏳" },
  { category: "flags", base: "1f3f4", smile: "🏴" },
  { category: "flags", base: "1f3c1", smile: "🏁" },
  { category: "flags", base: "1f6a9", smile: "🚩" },
  { category: "flags", base: "1f38c", smile: "🎌" },

  { category: "regional", base: "1f1e6", smile: "🇦" },
  { category: "regional", base: "1f1e7", smile: "🇧" },
  { category: "regional", base: "1f1e8", smile: "🇨" },
  { category: "regional", base: "1f1e9", smile: "🇩" },
  { category: "regional", base: "1f1ea", smile: "🇪" },
  { category: "regional", base: "1f1eb", smile: "🇫" },
  { category: "regional", base: "1f1ec", smile: "🇬" },
  { category: "regional", base: "1f1ed", smile: "🇭" },
  { category: "regional", base: "1f1ee", smile: "🇮" },
  { category: "regional", base: "1f1ef", smile: "🇯" },
  { category: "regional", base: "1f1f0", smile: "🇰" },
  { category: "regional", base: "1f1f1", smile: "🇱" },
  { category: "regional", base: "1f1f2", smile: "🇲" },
  { category: "regional", base: "1f1f3", smile: "🇳" },
  { category: "regional", base: "1f1f4", smile: "🇴" },
  { category: "regional", base: "1f1f5", smile: "🇵" },
  { category: "regional", base: "1f1f6", smile: "🇶" },
  { category: "regional", base: "1f1f7", smile: "🇷" },
  { category: "regional", base: "1f1f8", smile: "🇸" },
  { category: "regional", base: "1f1f9", smile: "🇹" },
  { category: "regional", base: "1f1fa", smile: "🇺" },
  { category: "regional", base: "1f1fb", smile: "🇻" },
  { category: "regional", base: "1f1fc", smile: "🇼" },
  { category: "regional", base: "1f1fd", smile: "🇽" },
  { category: "regional", base: "1f1fe", smile: "🇾" },
  { category: "regional", base: "1f1ff", smile: "🇿" },
];
