import { localesLanguages, defaultLocale } from "config/locales";

export const getUsersPreferredLanguages = () => {
  if (navigator.languages !== undefined) {
    return navigator.languages;
  } else if (navigator.language !== undefined) {
    return [navigator.language];
  } else {
    return undefined;
  }
};

export const parseLanguages = (): localesLanguages => {
  const userPref = getUsersPreferredLanguages();

  const match = userPref
    ? (userPref.find((lang) => lang in localesLanguages) as
        | localesLanguages
        | undefined)
    : undefined;

  if (match) {
    return match;
  }

  return defaultLocale;
};
