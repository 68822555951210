import type * as SentryType from "@sentry/browser";

import { User } from "providers/User/Context";
import { createIntervals } from "utils/createInterval";
import { ErrorTags, LoggerPayLoad } from "../type";

class CustomError extends Error {
  constructor(message: any) {
    super(message);
    const errorName = String(message).slice(0, 50);
    this.name = errorName;
  }
}

export class SentryLogger {
  private Sentry: typeof SentryType | null = null;

  async init() {
    try {
      const Sentry = await import("@sentry/browser");
      const { BrowserTracing } = await import("@sentry/tracing");

      const traced = new BrowserTracing({});

      Sentry.init({
        dsn: "https://5005b8b2dcc14f1b8d60839fa046d5f5@o1306507.ingest.sentry.io/6549206",
        integrations: [traced],
        tracesSampleRate: 1,
      });
      this.Sentry = Sentry;
    } catch (err) {
      console.error("error when try import Sentry:  ", err);
    }
  }

  isInit() {
    return !!this.Sentry;
  }

  async send(name: string, sendData: LoggerPayLoad) {
    const { errorTrace, custom, message } = sendData;
    try {
      const sendError = async () => {
        const { user_email, user_id, user_name } = this.identify();

        const {
          language,
          languages,
          userAgent,

          platform,
          appCodeName,
          appName,
          appVersion,
          vendor,
        } = window.navigator;

        this.Sentry?.captureException(new CustomError(errorTrace), {
          user: {
            email: user_email,
            id: user_id,
            username: user_name,
          },
          extra: {
            custom,
            message,
            name,
            path: {
              name: window?.location?.pathname,
              host: window?.location?.host,
              href: window?.location?.href,
            },

            navigator: {
              languages: language + " > [" + languages.join(",") + "]",
              userAgent,
              platform,

              etc: `${vendor || ""} ${appCodeName || ""} ${appName || ""} ${
                appVersion || ""
              } `,
            },

            trace: errorTrace,

            localStorage,
          },
        });
      };
      if (this.Sentry) {
        sendError();
      } else {
        const { clearIntervals, pushInterval } = createIntervals();
        let tryAmount = 0;

        pushInterval(() => {
          tryAmount++;

          if (this.Sentry) {
            sendError();
            clearIntervals();
          } else if (tryAmount > 15) {
            console.error(">> Error on create Sentry ", this.Sentry, sendData);
          }
        }, 1000);
      }
    } catch (error) {
      try {
        this.Sentry?.captureException(error, {
          extra: {
            name: ">> Error on errorTracker send + " + name,
          },
        });
      } catch (error) {
        console.error(">> Error on errorTracker send: ", error);
      }
    }
  }

  identify() {
    let user: User = {
      user_id: "null",
      user_name: "unLogged",
      user_email: "unLogged",
      avatar: "",
      eligible_daily_wheel: true,
    };

    try {
      const newUser: User | null = JSON.parse(
        localStorage.getItem("user") || "null",
      );
      if (newUser?.user_name) user = newUser;
    } catch {}

    return user;
  }

  error(error: Error | any, name: string, tags?: ErrorTags) {
    const { custom } = tags || {};

    try {
      this.send(name, {
        errorTrace: error,
        message: `${error}`.slice(0, 50),
        custom,
      });
    } catch (error) {
      console.error(">> Error on errorTracker error: ", error);
    }
  }
}
