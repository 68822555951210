const reg = /[0-9]*\.?[0-9]*/g;
export const validateNumbers = (value: string) => {
  value = value.replace(",", ".");
  const matchedValue = value.match(reg);
  if (matchedValue && matchedValue[0]) {
    return matchedValue[0];
  } else {
    return "";
  }
};
