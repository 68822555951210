export const toFixed = (
  value: string | number,
  fixedNumber: number = 8,
): string => {
  if (!value && value !== 0) {
    return `${value}` || "";
  }

  const fixedValue = Number(value).toFixed(fixedNumber);
  const result = fixedValue.match(
    /^[^.]+?(?=\.0*$)|^[^.]+?\..*?(?=0*$)|^[^.]*$/g,
  );

  return result ? `${result[0]}` : fixedValue;
};
