import React from "react";
import { Link, LinkProps } from "react-router-dom";
import { createLinkParamsFromString } from "./createLinkDescriptopFromString";

export const createGameForwardedLink = (to: string, label?: string) =>
  React.forwardRef<HTMLAnchorElement, Omit<LinkProps<any>, "to">>(
    (props, ref) => (
      <Link
        ref={ref}
        {...props}
        to={createLinkParamsFromString(to)}
        aria-label={label || to}
      />
    ),
  );
