import { Decimal } from "utils/decimal";

import { Coin } from "hooks";

import {
  toDecimal,
  isNumberString,
  getUsdValue,
  CoinConfiguration,
  rangeBetween,
} from ".";

export type CoinConfigType = "min" | "max" | "half" | "double";

const DECIMAL_ZERO = toDecimal("0");

export const getCoinAmountFromConfig = ({
  coinsConfig,
  type,
  betAmount,
  selectedCoin,
  showUSD,
}: {
  coinsConfig: Array<CoinConfiguration>;
  type: CoinConfigType;
  betAmount: string | number;
  selectedCoin: Coin;
  showUSD: boolean;
}): Decimal => {
  if (coinsConfig.length === 0) return DECIMAL_ZERO;
  const selectedCoinConfig = coinsConfig.find(
    (coinConfig) => coinConfig.coin === selectedCoin.coin,
  );

  if (!selectedCoinConfig) return DECIMAL_ZERO;

  const balance = showUSD
    ? getUsdValue(selectedCoin, selectedCoin.balance)
    : toDecimal(selectedCoin.balance);

  switch (type) {
    case "max":
    case "min":
      const configValue =
        selectedCoinConfig[type === "max" ? "maximum_bet" : "minimum_bet"];
      return !showUSD
        ? Decimal.min(toDecimal(configValue), balance)
        : Decimal.min(getUsdValue(selectedCoin, configValue), balance);
    case "double":
    case "half":
      if (!isNumberString(String(betAmount))) return DECIMAL_ZERO;

      const currentValue =
        toDecimal(betAmount)[type === "half" ? "div" : "mul"](2);

      const maxValue = Decimal.min(
        toDecimal(selectedCoinConfig.maximum_bet),
        balance,
      );

      const minValue = toDecimal(selectedCoinConfig.minimum_bet);

      return Decimal.min(Decimal.max(currentValue, minValue), maxValue);
  }
};
