import { User } from "providers/User/Context";

import { createIntervals } from "utils/createInterval";
import type { FirebaseApp } from "firebase/app";
import type FireStore from "firebase/firestore/lite";
import { ErrorTags, LoggerPayLoad } from "../type";

type Store = typeof FireStore;

interface Base {
  app: FirebaseApp;
  dataBase: FireStore.Firestore;
  store: Store;
}

const firebaseConfig = {
  apiKey: "AIzaSyCyrNOXMtyr9B6Di_4CHahVITfglPcZu9k",
  authDomain: "pasino-er.firebaseapp.com",
  projectId: "pasino-er",
  storageBucket: "pasino-er.appspot.com",
  messagingSenderId: "267292101993",
  appId: "1:267292101993:web:fd9832fe10da59bdd781f8",
};

export class FirebaseLogger {
  fb?: Base;

  async init() {
    try {
      const { initializeApp } = await import("firebase/app");
      const fireStore = await import("firebase/firestore/lite/");

      const app = initializeApp(firebaseConfig);
      const dataBase = fireStore.getFirestore(app);
      const store = fireStore;

      this.fb = {
        app,
        dataBase,
        store,
      };
    } catch (err) {
      console.error("error when try import firebase app: ", err);
    }
  }

  isInit() {
    return !!this.fb;
  }

  async send(name: string, sendData: LoggerPayLoad) {
    try {
      const { errorTrace, custom, message } = sendData;

      const sendError = () => {
        try {
          const {
            user_email = "",
            user_id = "",
            user_name = "",
          } = this.identify();

          const {
            language,
            languages,
            userAgent,

            platform,
            appCodeName,
            appName,
            appVersion,
            vendor,
          } = window.navigator;

          if (this.fb) {
            const { dataBase, store } = this.fb;

            const pathname = window?.location?.pathname;

            const errorName = (
              "> " +
              (message || errorTrace) +
              " > " +
              pathname
            ).replaceAll("/", "");
            const docs = store.doc(dataBase, "CritErrors", errorName);

            store.setDoc(docs, {
              [` - ${errorName} - `]: {
                user: {
                  user_email,
                  user_id,
                  user_name,
                },
                error: {
                  name,
                  message,
                  custom: JSON.stringify(custom) || "null",
                  path: {
                    pathname,
                    host: window?.location?.host,
                    href: window?.location?.href,
                  },
                  navigator: {
                    languages: language + " > [" + languages.join(",") + "]",
                    userAgent,
                    platform,

                    etc: `${vendor || ""} ${appCodeName || ""} ${
                      appName || ""
                    } ${appVersion || ""} `,
                  },
                  trace: errorTrace || "",
                  localStorage: JSON.stringify(localStorage),
                },
              },
            });
          }
        } catch (err) {
          console.error(">> ", err);
        }
      };
      if (this.isInit()) {
        sendError();
      } else {
        const { clearIntervals, pushInterval } = createIntervals();
        let tryAmount = 0;

        pushInterval(() => {
          tryAmount++;

          if (this.isInit()) {
            sendError();
            clearIntervals();
          } else if (tryAmount > 15) {
            console.error(">> Error on create Sentry ", this.fb, sendData);
          }
        }, 1000);
      }
    } catch (error) {
      console.error(">> Error on errorTracker send: ", error);
    }
  }

  identify() {
    let user: User = {
      user_id: "null",
      user_name: "unLogged",
      user_email: "unLogged",
      avatar: "",
      eligible_daily_wheel: true,
    };

    try {
      const newUser: User | null = JSON.parse(
        localStorage.getItem("user") || "null",
      );
      if (newUser?.user_name) user = newUser;
    } catch {}

    return user;
  }

  error(error: Error | any, name: string, tags?: ErrorTags) {
    const { custom } = tags || {};

    try {
      this.send(name, {
        errorTrace: error,
        message: `${error}`.slice(0, 50),
        custom,
      });
    } catch (error) {
      console.error(">> Error on errorTracker error: ", error);
    }
  }
}
