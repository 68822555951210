import { prepareRender } from "preparedPages";
import * as serviceWorkerRegistration from "serviceWorkerRegistration";

import LazyComponentLoader from "config/LazyComponentLoader";
import { isInIFrame, isRecursion } from "utils/isInIFrame";
import "./index.css";

const isRecursionRender = isRecursion();

// When rendering recursively, the top-level localStorage will be cleared. Because of this, here isRecursionRender check
if (!isRecursionRender && isInIFrame()) {
  localStorage.clear();
}

const renderComponent = async () => {
  const { render } = await import("react-dom");
  const { default: React, Suspense } = await import("react");

  const App = React.lazy(
    async () => await LazyComponentLoader(import("containers/App")),
  );

  const Main = () => {
    return (
      <Suspense fallback={<div className="mainFallBack" />}>
        <App />
      </Suspense>
    );
  };

  return render(<Main />, document.getElementById("root"));
};

if (!isRecursionRender) {
  prepareRender(renderComponent);
}

serviceWorkerRegistration.register();

const minutes = 10;

setInterval(() => {
  console.clear();
}, minutes * 60 * 1000);
