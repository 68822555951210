import { track } from "./track";

export const createLazyGetter = <T extends unknown>(
  imp: () => Promise<any>,
  name = "default",
) => {
  interface ConstructType {
    instance: T | null;
    get: (setter: (instance: T) => void) => void;
  }
  const construct: ConstructType = {
    instance: null,
    get: async function (setter) {
      const importElement = async () => {
        try {
          const element = (await imp())[name] as unknown as T;
          this.instance = element;
          setter(element);
        } catch (error) {
          const errorText = "Error when try import " + imp.toString();

          track()?.errors(error, "createLazyGetter", {
            custom: {
              errorText,
            },
          });

          throw errorText;
        }
      };

      if (this.instance) {
        setter(this.instance);
      } else {
        importElement();
      }
    },
  };

  return construct;
};
