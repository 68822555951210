import { track } from "./track";

export const getTag = (value: unknown) => {
  if (value == null) {
    return value === undefined ? "[object Undefined]" : "[object Null]";
  }
  return Object.prototype.toString.call(value);
};

export const isObjectLike = (value: unknown): value is Object => {
  return typeof value === "object" && value !== null;
};

export const isBoolean = (value: unknown): value is boolean => {
  return (
    value === true ||
    value === false ||
    (isObjectLike(value) && getTag(value) === "[object Boolean]")
  );
};

export const isEqual = (
  object: Record<string, any>,
  comparisonObject: Record<string, any>,
) => {
  try {
    const objectString = JSON.stringify(object);
    const comparisonString = JSON.stringify(comparisonObject);

    return objectString === comparisonString;
  } catch (error) {
    console.error("error in isEqual: ", error);

    track()?.errors(error, "error in isEqual", {
      custom: {
        explain: "it mast be an objects",
        firstObject: object,
        secondObject: comparisonObject,
      },
    });
    return false;
  }
};
