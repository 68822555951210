import React from "react";

interface IDateParts {
  year: number;
  month: number;
  day: number;
  hours: number;
  minutes: number;
  seconds: number;
  milliseconds: number;
}

export const getDateParts = (date: Date, UTC?: boolean): IDateParts => {
  return {
    year: UTC ? date.getUTCFullYear() : date.getFullYear(),
    month: UTC ? date.getUTCMonth() + 1 : date.getMonth() + 1,
    day: UTC ? date.getUTCDate() : date.getDate(),
    hours: UTC ? date.getUTCHours() : date.getHours(),
    minutes: UTC ? date.getUTCMinutes() : date.getMinutes(),
    seconds: UTC ? date.getUTCSeconds() : date.getSeconds(),
    milliseconds: UTC ? date.getUTCMilliseconds() : date.getMilliseconds(),
  };
};
