export const debounce = () => {
  let startTime: number | null = null;
  let pushed = false;

  return (f: Function, timer: number) => {
    pushed = false;
    if (!pushed) {
      startTime = Date.now();
      pushed = true;
    }

    let interval = setInterval(() => {
      let now = Date.now();

      if (startTime && now - startTime > timer) {
        f();
        pushed = false;
        startTime = null;
      }
      if (!pushed) {
        clearInterval(interval);
      }
    }, 50);
  };
};
