import { RawLocalesList } from ".";

const messages = {
  username: "Username",
  password: "Password",
  about: "About",
  home: "Home",
  offline: "Offline",
  copied: "Copied",
  unrecognizedError: "Something went wrong, please try again.",

  login: "LOGIN",
  register: "SIGN UP",
  termsOfUse: "Terms of use",

  "feyorra-utilities": "Feyorra Utilites",
  homeScreen: {
    default: "Pasino Originals",
    basilisk: "Basilisk Slots",

    featuredGame: "Featured game",
    playNow: "Play Now!",
    winnersRow: "BIGGEST WINNERS TODAY",
    betsRow: "LATEST BETS",
    slot_games: "Slot Games",
    table_games: "Table Games",
    live_games: "Live Games",

    join: {
      title: "Join Now and Play",
      button: "CREATE ACCOUNT",
    },
  },

  form: {
    usernameLabel: "Username",
    emailLabel: "Your email",
    loginLabel: "Username",
    passwordLabel: "Your password",
    passwordRepeatLabel: "Password repeat",
    referLabel: "Referral/Promo Code",
    fullReferLabel: "Enter Referral/Promo Code (optional)",

    login: {
      title: "Welcome to Pasino",
      description: "Don’t have an account? {SignUpLink}",
      signUp: "Sign up",
      button: "Log in",
      orSeparator: "or log in using",
      forgotPassword: "Forgot your password? {recover}",
      recover: "Recover it",
    },

    register: {
      title: "Welcome to Pasino",
      description: "Already have an account? {LogInLink}",
      logIn: "Log in",
      agreement: `I agree with the Terms of Use and Privacy Policy. I'm at least 18 years and agree to the collection of information in cookies. I confirm gambling isn't forbidden in my jurisdiction. `,
      privacyPolicy: "Privacy policy",
      termsOfUse: "Terms of use",
      button: "Create account",
      nextButton: "next",
      orSeparator: "or sign in using",

      errors: {
        userName: {
          trim: "There should be no spaces at the beginning or end of your user name.",
          short: "User name is too short.",
          long: "Sorry, but user name is too long.",
          symbols:
            "Only latin letters (A-Za-z), numbers (0-9) and underscore (_) are allowed.",
        },
        email: {
          format: "Incorrect email format.",
        },
        password: {
          trim: "There should be no spaces at the beginning or end of your password.",
          short: "Password is too short.",
          long: "Sorry, but password is too long.",
        },
        passwordRepeat: {
          match: "Passwords do not match.",
        },
      },
    },

    requestNewPassword: {
      title: "Request new password",
      description:
        "Please enter your username or email to request a new password",
      input: "Username or email address",
      button: "Request new password",
      success:
        "You have successfully requested a new password. Please check your emails",
    },

    passwordRecovery: {
      title: "PASSWORD RECOVERY",
      description: "Please enter your new password",
      passwordLabel: "Password",
      passwordRepeatLabel: "Password repeat",
      button: "UPDATE PASSWORD",
      success: "Your password was successfully confirmed.",
      error: "Something went wrong, please try again.",
    },

    confirmEmail: {
      success: "Your email was successfully confirmed.",
      error: "Something went wrong, please try again.",
    },

    checkEmail: {
      success:
        "You have been successfully registered. Check your email to complete registration.",
    },

    tfaChallenge: {
      title: "CHECK GOOGLE AUTHENTICATOR or AUTHY ON YOUR MOBILE.",
      description:
        "Your account has Two-Factor Authentication enabled. To login, you must enter your One-Time Password code from your authentication application.",
      input: "CODE",
      button: "LOG ME IN",
      error: "Something went wrong, please try again.",
    },

    exchange: {
      convertFrom: "CONVERT FROM",
      convertTo: "CONVERT TO",
      quantity: "QUANTITY",
      continueButton: "CONTINUE",
      cancelButton: "CANCEL",
      confirmButton: "CONFIRM",

      converting: "CONVERTING",
      to: "TO",
      fees: "FEES",
      exchangeFee: "exchange fee",
    },
  },

  copyright: "2022 © Basilisk Entertainment S.R.L",
  terms: "Terms",
  privacy: "Privacy",
  gambleAware: "Gamble Aware",

  banners: {
    winBonuses: {
      won: "win",
      bonus: "Bonuses",
    },
  },

  winBonuses: {
    title: "Feyorra - Wager Mining",
    main: "Feyorra will distribute 40% of the initial supply via Wager Mining. Users are rewarded by placing bets on Pasino.com and FaucetPay.io games. Every $1 equivalent in any cryptocurrency which is available on the games mines 0.07 FEY added to your wallet every hour.",

    left: {
      text: "Your pending rewards will be credited to your balance every 1 hour given that you have wagered more than $1. Once credited to your profile, you'll see it in the table below.",
      title: "PENDING FEYORRA MINED",
    },

    right: {
      text: "This is the total Feyorra that has been credited to your account so far. This excludes the pending rewards (shown at the left side) that are yet to be credited to your wallet.",
      title: "TOTAL FEYORRA MINED",
    },

    table: {
      title: "Last Rewards",
      emptyTable: "No reward records available.",
      header: {
        datetime: "Date and Time",
        credited: "Credited",
        total: "Total wagered",
      },
    },
  },

  availableBonuses: {
    title: "Available Bonuses to claim",
    text: "Do you want to claim all your available bonuses?",
    buttons: {
      yes: "YES",
      no: "NO",
    },
  },

  fpDeposit: {
    title: "Deposit With FaucetPay.io",
    button: "Make Payment",
    label: "Amount",
  },

  affiliate: {
    buttonTitle: "Referral Rewards",
    title: "INVITE & EARN",
    subtitle: "Invite players and earn from every bet your friends place!",
    titleButtons: {
      dashboard: "Affiliate Dashboard",
      rules: "Rules",
      terms: "Terms & Conditions",
    },

    statistics: {
      title: "Share your invitation link",
      titleText: "Share your invitation link to invite players to Pasino",

      myLink: "My Referral Link",

      buttons: {
        copy: "Copy link",
        tweet: "Tweet link",
        facebook: "Share on facebook",
        download: "Download Banners & Card",

        tweetLinkText:
          "Start playing at Pasino for great rewards! One of the best iGaming platforms.",
      },
    },

    refs: {
      title: "Referred Users",

      usersTable: {
        bets: "Bets",
        registration: "Registration date",
        total: "Total Wagered",
        user: "User",
      },
    },

    history: {
      title: "Total Earned",
      tableTitle: "Total Referral Income",

      table: {
        coin: "Coin",
        earned: "You earned",
      },
    },

    rates: {
      title: "Earn rewards",
      titleText: "Earn rewards when your invited users play",

      text_p1: "You get",
      text_p2: "of each bet",

      crash: "Crash",
      plinko: "Plinko",
      dice: "Dice",
      slots: "Slots",
      videopoker: "Video Poker",
      limbo: "Limbo",
      diamonds: "Diamonds",
      roulette: "Roulette",
      blackjack: "Blackjack",
      keno: "Keno",
      mines: "Mines",
      towers: "Towers",
      wheel: "Fortune Quest ",
    },

    invites: {
      title: "Your invites",
      titleText: "Track the status of your invitations",

      you: "You",
      level: "Referrals",
      players: "Players",
      view: "View",

      userList: {
        title: "Total Earned",
        table: {
          user: "User",
          date: "Date",
          bets: "Bets",
          total: "Total Wagered",
        },
      },
    },
  },

  footer: {
    copyright: "2022 © Basilisk Entertainment S.R.L",
    rights: "All Rights Reserved.",
    blog: "Blog",
    contact: "Contact Us",

    description: `This website is operated by Basilisk Entertainment S.R.L, a company registered and established under the laws of Costa Rica.
      Contact us at hi@pasino.com. Gambling can be addictive.
      Please play responsibly.
      If you need support in relation to your gambling habits, you can find more information at Gambling Therapy.`,
    url: "Read more about responsible gambling here.",
  },

  gameTemplate: {
    statistics: {
      ranking: "RANKING",
      history: "HISTORY",
      yourBets: "YOUR BETS",
      fairness: "FAIRNESS",
      wageringRace: "Wagering Race",
      affiliateBattle: "Affiliate Battle",

      chain: "CHAIN",
      allBets: "All Bets",

      table: {
        rank: "#",
        player: "PLAYER",
        input: "INPUT",
        losses: "LOSSES",
        earnings: "EARNINGS",
        totalWagered: "TOTAL WAGERED",
        netProfit: "NET PROFIT",
      },

      historyTable: {
        id: "Game ID",
        payout: "Result",
        hash: "Hash",

        modal: {
          title: "Round Information",

          point: "Result Point",
          amount: "Amount",
          user: "User Name",
          profit: "Profit",

          id: "Game ID",
          crash: "Result",
          seed: "Hash",

          button: "Verify",

          copied: "Hash copied",
        },

        trends: {
          title: "TRENDS",

          help: {
            title: "Explanation of Trend Chart",
            explanation:
              "As shown in the chart, the corresponding sequence is:",
          },
        },
      },

      allBetsTable: {
        game: "Game",
        bet: "Bet Amount",
        payout: "Payout",
        profit: "Profit",
        user: "User",
      },

      yourBetsTable: {
        tHead: {
          game: "Game",
          betAmt: "Bet Amount",
          payout: "Payout",
          profit: "Profit",
        },

        share: {
          share: "Share",
          linkToShare: "Link to share",
          shareToSocial: "Share to social",
          cancel: "Cancel",
          shareMessage: "Hey, guys! I just made a profit of [x] at Pasino.com!",
        },

        betData: {
          title: "Bet information",
          bet: "Bet",
          payout: "Payout",
          profit: "Profit",
          revealed: "Revealed",
          nonce: "Nonce",

          revealedRotate: "Rotate your seed pair",
          verify: "Verify",
          rotateSSeed: "Rotate Server Seed to Reveal",
          serverSeed: "Server seed",
          clientSeed: "Client seed",

          resultNotify: {
            winTitle: "Congratulations!",
            loseTitle: "Bad luck!",
            winText: "You Won",
            loseText: "You Lost",
          },
        },

        allHistoryButton: "All { game } history",
      },

      wageringTable: {
        timeTitle: "Contest Ends In",
        wageredTitle: "Total Amount Wagered",
        wageringRace: "Wagering race",
        position: "Rank",
        user: "Player",
        reward: "Reward",
        total: "Wagered",
        processing: "Processing...",
        currentRanking: "Current Ranking",

        text: "Play as much as you can! You can get closer to the main prize by increasing your total wagered amount.",
      },

      affiliateTable: {
        timeTitle: "CONTEST ENDS IN",
        wageredTitle: "Your Total Wagered Amount (Downline)",

        position: "Rank",
        user: "Player",
        reward: "Reward",
        total: "Total Wagered (Downline)",
        processing: "Processing...",

        text: "Refer as many people as you can and encourage them to play at Pasino! The more your referred players play, the closer you will get to the main prize! Bets in all cryptocurrencies are counted and their value is converted to USD.",
      },
    },
  },

  exchange: {
    title: "Exchange",
  },

  help: {
    title: "Frequently Asked Questions",

    questions: {
      1: {
        q: "What if I forgot my password?",
        a: "If you forget your password, you can reset it with our “forgot password” link. After applying for password reset, please check your email and follow the recovery instructions.",
      },
      2: {
        q: "How do I reset my Google authenticator?",
        a: "Please contact our support team to reset your 2FA code.",
      },
      3: {
        q: "How long does it take until my deposit is reflected on my balance?",
        a: "It depends on the blockchain and your transfer fee. It may take 10 minutes or even several hours.",
      },
      4: {
        q: "What should I do if there was a game bug?",
        a: "Please try to collect as much informations as possible and contact our support with the details. We need to be able to reproduce the bug in order to provide a refund.",
      },
      5: {
        q: "How long does it take to withdraw?",
        a: "Withdrawals will be processed instantly. This may take 5-10 minutes to reflect on the blockchain.",
      },
      6: {
        q: "What are the minimum deposits?",
        a: "Our minimum deposits are:",
      },
    },
  },

  profile: {
    title: "Profile",
    favouriteGames: {
      title: "Top 3 Favorite Games",
      wagered: "Wagered",
    },
    winHistory: {
      title: "Contest Win History",
      date: "Date",
      position: "Position",
      prize: "Prize",
      noFavouriteGames: "This user has not favourite games.",
      noWins: "This user has not won any wagering contests.",
    },
    statistics: {
      netProfit: "Profit",
      totalWagered: "Wagered",
      profitAth: "Profit (ATH)",
      profitAtl: "Profit (ATL)",
      level: "Level",
      bets: "Bets",
    },

    hiddenUser: "Profile hidden",
    hiddenStatistic: "Statistics Hidden",
  },

  rain: {
    title: "Rain",
    send: "Send",
  },

  wallet: {
    title: "Wallet",
    emptyTable: "There are no records to show",
    gemInfo:
      "GEM is a fictitious cryptocurrency token that is used for testing Pasino games. GEM does not have any value and its sole purpose to enable users to test Pasino without spending any real cryptocurrencies. Deposits and withdrawals are not available for GEM!",
    pltInfo:
      "PLT, or Pasino Lottery Token, is a type of token that specifically represents a lottery token within our ecosystem. It is not usable outside of our platform and has no value beyond its intended purpose within the lottery system. As such, it cannot be deposited or withdrawn from our ecosystem.",
    withdraw: {
      title: "Withdraw",
      ballance: "Balance",
      address: "Withdrawal Address",
      amount: "Withdrawal Amount",
      allButton: "All",
      minButton: "min",
      sendButton: "Place Withdrawal",
      receive: "You receive:",
      fees: "Fee:",
      addressPlaceholder: "Write address here",

      switch: {
        direct: "DIRECT WITHDRAWAL",
        fp: "FAUCETPAY (LESS FEES)",
      },
    },
    records: {
      title: "Records",

      deposit: "Deposit",
      withdraw: "Withdraw",

      coin: "Coin",
      amount: "Amount",
      send_amount: "Send Amount",
      status: "Status",

      details: "Details",

      date: "Date",
      credited: "Status",
      confirmations: "Confirms",

      method: "Method",
      transaction_id: "Transaction ID",
    },
    bills: {
      title: "Bills",
      table: {
        amount: "Amount",
        balance_after: "Balance After",
        coin: "Coin",
        datetime: "Time",
        type: "Type",
      },
    },

    deposit: {
      title: "Deposit",
      addressLabel: "Deposit Address",
      tagLabel: "Payment Tag / Memo ",

      reminder: "NOTE",
      firstReminder: "Minimum Direct Deposit:",
      fpReminder: "Minimum FaucetPay Deposit:",
      confReminder: "Confirmations Required:",

      secondReminder:
        "Please do not deposit invalid assets to this address. We are not responsible for lost funds.",
      thirdReminder:
        "We do not support deposits from smart contracts. A recovery fee will be charged for such transactions.",
      BTTWarning:
        "We are using the new BTT token (TRC-20). If you send the old BTT token (TRC-10), it will not be credited to your account & will be lost.",

      button: "DEPOSIT",
    },
    vault: {
      title: "Vault",
      in: "Transfer In",
      out: "Transfer Out",
      amountOut: "Amount to Transfer Out",
      amountIn: "Amount to Transfer In",
      all: "All",
      min: "Min",
      tfa: "2FA Code",
      pass: "Password",
      transferOut: "Transfer From Vault",
      transferIn: "Transfer to Vault",
      assets: "Assets in Vault",
      total: "Total",
    },
    exchange: {
      title: "Exchange",
      payWith: "You pay with",
      youGet: "You Get",
      swap: "Swap",
      balance: "Balance",
      min: "Min",
    },
  },

  settings: {
    title: "Settings",
    email: {
      switcher: "EMAIL",
      title: "Change Email",
      inputText: "Email Address",
      button: "Save",
    },
    password: {
      switcher: "PASSWORD",
      title: "Change Password",
      inputOldPassword: "Current Password",
      inputNewPassword: "New Password",
      inputPasswordRepeat: "Repeat New Password",
      button: "Save",
      errors: {
        notMatch: "Passwords do not match",
      },
    },
    tfa: {
      switcher: "2-FACTOR",
      title: "Two-factor Authentication",
      enabled: {
        description:
          "Great job! Two-factor Authentication is activated on your account! If you would like to disable it, please enter the temporary OTP (code) from your mobile device (such as Google Authenticator or Authy) in the field below.",
        tokenText: "2FA Code",
        inputTexst: "Code",
        button: "Disable 2FA",
      },
      disabled: {
        description:
          "Two-factor Authentication is not enabled on your account. For greater security, please consider enabling 2FA on your account.",
        tokenText: "Secret Key",
        codeText: "Code",
        button: "Enable 2FA",
      },
    },

    profile: {
      switcher: "AVATAR",
      avatar: {
        title: "Change Avatar",

        messages: {
          error: "Images up to 400x400 px and up to 1 MB in size are allowed",
          loading: "Checking...",
          good: "The image meets the requirements",
        },

        buttons: {
          send: "Send",
          edit: "Edit",
          change: "Change Image",
          save: "Save",
          crop: "Crop",
        },

        data: {
          preview: "Preview",
        },
      },
    },

    sessions: {
      switcher: "ACTIVE SESSIONS",
      title: "Active Sessions",
      table: {
        browser: "Browser",
        country: "Country",
        IPAddress: "IP Address",
        lastUsed: "Last Used",
        action: "Action",
      },
    },
  },

  userMenu: {
    logout: "Logout",
    login: "Log In",
    profile: "Profile",
    settings: "Settings",
    inventory: "INVENTORY",
  },

  crashInterface: {
    bet: {
      button: {
        placeABet: "Bet",
        placeANextRound: "For next round",
        loadBet: "Wait",
        loadCancel: "Cancel bet",
        waitForServerAnswer: "For confirmation",
        cashOut: "Cash out",

        autoStart: "Start Auto",
        autoStop: "Stop Auto",
      },
      switch: {
        manual: "Manual",
        auto: "Auto",
      },
      menu: {
        buttons: {
          min: "min",
          max: "max",
          half: "1/2",
          double: "2x",
        },
        amount: "Bet Amount",
        profit: "Profit on win",
        payout: "Payout",
        opportunity: "Opportunity",

        warning: "Are you sure?",
      },
    },

    autoBet: {
      cashout: "Cashout At",
      baseCashout: "Cashout At",
      stopOnProfit: "Stop on Profit",
      stopOnLoss: "Stop on Loss",
      betAmount: "Bet Amount",
      baseBetAmount: "Bet Amount",
      onWin: "On Win",
      reset: "Reset",
      changeBetBy: "Change Bet By",
      onLoss: "On Loss",
      totalPayout: "Total Payout",
      maximumBetAmount: "Maximum Bet Amount",
      maximumBets: "Maximum Bets",
    },

    messages: {
      accepted: "Your bet has been accepted!",
      win: "You've made a profit of ",
      noLogin: "You must be logged in.",
      noBalance: "Balance too low",
    },

    table: {
      player: "Player",
      bet: "Bet",
      profit: "Profit",
    },
  },

  dice: {
    manual: {
      base: {
        bet: "Bet Amount",
        profit: "Profit on win",
        odds: "Odds",
        chance: "Chance",
      },
    },
    auto: {
      base: {
        bet: "Base Bet Amount",
        profit: "Profit on win",
        odds: "Odds",
        chance: "Chance",
      },
      decisions: {
        stopBetBy: "Stop Betting",
        returnToBase: "Return to base bet",
        increase: "Increase bet by (%)",
        change: "Change Cashout/Chance at",

        onWin: "On Win",
        onLoss: "On Loss",
      },
      roll: {
        numberOfBets: "Number Of Bets",
      },
      select: {
        winSwitch: "Switch when win",
        lossSwitch: "Switch when loss",
        betSwitch: "Switch after each bet",
        noSwitch: "No switch",

        title: "Select Mode",
      },
    },
  },

  videopoker: {
    combinations: {
      royalFlush: "royal flush",
      straightFlush: "straight flush",
      fourOfAKind: "4 of a kind",
      fullHouse: "full house",
      flush: "flush",
      straight: "straight",
      threeOfAKind: "3 of a kind",
      twoPair: "2 pair",
      pairOfJacksOrBetter: "pair of jacks or better",
    },
    buttons: {
      bet: "BET",
      rebet: "REBET",
      deal: "DEAL",
      max: "max",
      min: "min",
    },
    inputLabel: "Amount",
  },

  diamonds: {
    button: {
      bet: "BET",
      autoBet: "start autobet",
    },
    switch: {
      auto: "auto",
      manual: "manual",
    },
    input: {
      betAmount: "Bet Amount",
      onWin: "On Win",
      onLoss: "On Loss",
      stopOnProfit: "Stop on Profit",
      stopOnLoss: "Stop on Loss",
      increaseBy: "Increase by:",
      numberOfBets: "Number of Bets",
    },
  },

  keno: {
    button: {
      bet: "BET",
      autoBet: "start autobet",
    },
    switch: {
      auto: "auto",
      manual: "manual",
    },
    input: {
      betAmount: "Bet Amount",
      onWin: "On Win",
      onLoss: "On Loss",
      stopOnProfit: "Stop on Profit",
      stopOnLoss: "Stop on Loss",
      increaseBy: "Increase by",
      numberOfBets: "Number of Bets",
    },
    risk: {
      low: "Low",
      medium: "Medium",
      high: "High",
    },
    autoSelect: "Auto Pick",
    clear: "Clear Table",
  },

  mines: {
    button: {
      bet: "BET",
      autoBet: "start autobet",
    },
    switch: {
      auto: "auto",
      manual: "manual",
    },
    input: {
      mines: "Mines",
      totalBet: "Total Bet",
      onWin: "On Win",
      onLoss: "On Loss",
      stopOnProfit: "Stop on Profit",
      stopOnLoss: "Stop on Loss",
      increaseBy: "Increase by:",
      numberOfBets: "Number of Bets",
    },
  },

  limboInterface: {
    controller: {
      payout: "Target Multiplier",
      chance: "Win Chance",
    },
    bet: {
      auto: {
        change: "Change Target Multiplier To:",
      },
    },
  },

  deposit: {
    addressLabel: "Deposit Address",

    reminder: "Reminder:",
    firstReminder:
      "1. Please do not deposit invalid assets to this address. We are not responsible for lost funds.",
    secondReminder: "2. Smart contract deposits are not recommended.",
  },

  fairnessInformation: {
    title: "FAIRNESS",

    serverSeed: "Server Seed",
    serverSeedHashed: "Server Seed (Hashed)",
    clientSeed: "Client Seed",
    nonce: "Nonce",

    button: "Verify",
    rotate: "Rotate Server Seed",
  },
  plinko: {
    bet: {
      label: "BET",

      manual: "MANUAL",
      auto: "AUTO",

      notLoggedIn: "Please create an account or log in!",

      buttons: {
        min: "min",
        half: "1/2",
        double: "2x",
        max: "max",
      },
    },

    risk: {
      label: "RISK",

      low: "Low",
      average: "Medium",
      high: "High",
    },

    rows: {
      label: "ROWS",
      count: "{count, plural, =0 {no rows} one {# row} other {# rows}}",
    },

    autoBet: {
      startLabel: "START AUTO",
      stopLabel: "STOP AUTO",

      onWin: "On Win",
      onLoss: "On Loss",
      numberOfBets: "Number of Bets",
      changeBetBy: "Change Bet By (%)",
      resetToBaseBet: "Reset to Base Bet",
      stopBetting: "Stop Betting",

      maximumStakeReached: "Maximum stake reached",
      minimumStakeReached: "Minimum stake reached",
    },
  },

  chat: {
    serverMessage: "Server Message",
  },

  gameHistory: {
    title: "",
    rows: {
      amount: "Amount",
      id: "ID",
      netProfit: "Net Profit (Inclusive)",
      payout: "Payout",
      profit: "Net Profit",
    },
  },

  contactUs: {
    title: "Contact Us",
    text: {
      p1: "If your inquiry is related to deposit, please mention your transaction ID, address, and amount in the body. If you did not receive your withdrawal, please contact your receiving wallet (and provide the transaction ID shown at the withdrawal page) before you contact us. The transaction ID we show you at the withdrawals page contains all of the information related to find and track your withdrawal.",
      p2: "Please don't create more than one tickets per issue. Creating multiple tickets will only result in delays. Also, please ensure that you write your message in English as our staff is only English speaking.",
      p3: "We request you to be detailed while sending us your query. Due to a large number of queries we receive daily, we can only respond to a few which meet the listed criteria. We will endeavor to respond to your email in 4 to 8 hours or less.",
    },
    form: {
      name: {
        title: "Full Name",
        placeholder: "What's your name?",
      },
      mail: {
        title: "Email Address",
        placeholder: "What's your email?",
      },

      type: {
        title: "Type",
        types: {
          general: "General",
          accountRelated: "Account-Related",
          depositWithdrawals: "Deposit and Withdrawals",
          support: "Technical Support",
        },
      },

      message: {
        title: "Message",
        placeholder: "What would you like to ask?",
      },

      button: {
        title: "Contact Us",
      },
    },
  },

  diceApi: {
    title: "Dice API - Pasino",
    text: {
      p1: "Thank you for your interest in our Dice API. Here at Pasino, we have the best service to help you develop trustworthy betting software and bots.",
      p2: "Our Dice API is available for both personal and commercial uses. This means that you can use it to place high-frequency bets or create software to resell our dice game.",
      p3: "To obtain our Dice API access, please fill in the form below and we'll get back to you within 12 business hours.",
    },
    buttonText: "send",
    message: {
      title: "Use Case",
    },
  },

  limboGame: {
    bet: {
      base: {
        amount: "Amount",
        profit: "Profit",
        payout: "Payout",
        chance: "Chance",
      },
    },

    manual: {
      title: "Manual",
    },

    auto: {
      title: "Auto",

      decisions: {
        stopBetBy: "Stop Betting",
        returnToBase: "Return to base bet",
        increase: "Increase bet by (%)",
        change: "Change Payout",

        onWin: "On Win",
        onLoss: "On Loss",
      },

      limits: {
        bet: "Max bets",
        amount: "Max amount",
      },
    },
  },
  liveStats: {
    title: "Live Statistics",

    rase: {
      title: "Race",
      day: "Hours",
      reward: "Prize",
      total: "Total Wagered",
    },
    bets: {
      title: "Bets",

      profit: "Profit",
      amount: "Amount",
      wins: "Wins",
      loses: "Loses",
    },
  },
  slot: {
    title: "Slot Games",
    playNow: "Play Now",
    hot: "Hot Games",
    new: "New Games",
    live: "Live Games",
    top: "Table Top",
  },

  bonusPage: {
    title: "Win Bonuses",

    headerText:
      "Playing at Pasino must be a fulfilling experience. Hence, you can now earn bonuses for your activity and gameplay here.",

    details: "More Information",
    details_2: "Details",

    winBonuses: {
      title: "WIN BONUSES",
      text: "Playing at Pasino must be a fulfilling experience. Hence, you can now earn bonuses for your activity and gameplay here.",
    },
    rakeback: {
      title: "RAKEBACK (REFUND)",
      text: "Get a refund on every bet you place. Your rakeback percentage depends on your account level!",
    },

    shitCode: {
      title: "Bonus Code",
      text: "Redeem your bonus codes for great rewards!",
    },
    medals: {
      title: "Medals",
      text: "Are you a true player? Collect all medals and get rewarded!",
    },
    level: {
      title: "Level Up Rewards",
      text: "Every time you level up, you earn rewards in USDT!",
    },

    modals: {
      rake: {
        title: "RakeBack",
        subTitle:
          "Get a rakeback on every bet you place. Your rakeback amount depends on your level!",

        activate: {
          active: "Activated at",
          text: "Refresh every 5 minutes",
          button: "Claim",
        },
      },
      code: {
        title: "Bonus Code",
        subTitle: "Create or redeem bonus codes",

        content: {
          redeem: {
            placeholder: "Redeem your codes here",
            button: "Redeem now",
          },
          create:
            "Do you want to distribute your own bonus codes? Create your own codes here!",
          button: "Create code",
          history: "View history",
          menu: "View menu",

          status: {
            claimed: "CLAIMED",
            warning: "UNCLAIMED",
          },

          table: {
            amount: "Amount",
            code: "Code",
            claimed: "Claimed By",
            status: "Status",
          },
        },
      },
      medal: {
        title: "Medals",
        details: "Medal Details",

        content: {
          main: "Achievement unlocked every 5 medals",
        },
      },
    },

    bonuses: {
      usdt: {
        content: {
          title: "Wager to unlock USDT",
          how: "How to unlock",

          mainTitle: "AMOUNT UNLOCKABLE",
          unlocked: "Unlocked",
          locked: "Locked",
          claim: "Claim",

          formula: {
            unlock: "Unlock",
            wager: "Wager",
          },
          minimum: "Minimum required to claim:",

          claimed: "Total claimed",
        },

        enjoy: "Enjoy the game while unlocking USDT to your wallet",

        bottom: {
          record: "Record",
          title: {
            active:
              "You can <span>deposit</span> now to receive a deposit bonus!",
            noActive: "There is no bonus event going on at the moment.",

            highLight: "Deposit ",
            default: "to claim your USDT bonus now",
          },
          noData: "No data yet!",
        },
      },
    },

    rules: {
      title: "Deposit Bonus Rules",
      depositNow: "Deposit Now",

      text: {
        _1: `Do you know what’s the most exciting part about signing up and playing at a crypto casino? It’s all about the lucrative bonuses and fun promotions fit for all types of players.`,
        _2: `Pasino, the leading crypto gambling platform in the world, offers you, our loyal players, not just one but four rewarding deposit bonuses of up to 780% deposit match!`,
        _3: `Not only it’s the perfect time to make extra crypto, but it is your opportunity to get hold of some extra USDT to boost your bankroll.  We’ll reward you with USDT for your deposits which you can use at your discretion.`,

        _4: `Your Pasino crypto adventure begins on your first deposit.
        Make a deposit today and get a massive 180% deposit match at Pasino crypto casino! `,

        _5: `A second deposit bonus of 200% awaits you with your second deposit into the world's best crypto casino! Don’t wait, and enjoy more crypto gambling fun now!`,

        _6: `Yes! Another 220% deposit bonus just for you!
         All you have to do is make your third deposit at Pasino and let our team reward you for your loyalty.`,

        _7a: `A 240% Fourth Deposit Bonus! Almost unheard of in the crypto gambling space, but our team makes it happen at Pasino.com. Don’t delay; take advantage of this exciting crypto casino bonus to play your favorite games, and win big!`,
        _7b: `At Pasino.com, we offer our players an exciting headstart when betting with crypto.  A world of exciting crypto gambling adventure awaits you: sign up for an account today!`,
        _7c: `*Terms and conditions apply`,
      },
      subTitle: {
        text: "Deposit Bonus",

        _1: "1st",
        _2: "2nd",
        _3: "3rd",
        _4: "4th",

        full: {
          _1: "First",
          _2: "Second",
          _3: "Third",
          _4: "Fourth",
        },
      },

      cards: {
        deposit: "Deposit",
        bonus: "Bonus",
      },
    },

    deposit: {
      upTo: "up to",
      bonus: "bonus",
    },

    bonusModal: {
      headerTitle: "First Deposit Bonus",
      description:
        "There are 3 level deposit bonuses. Make a deposit based on your preferences!",
      title: "DEPOSIT BONUS UP TO",
      bonus: "BONUS",

      describe: {
        deposit: "Deposit",
        usd: "{usd} or above",
        percent: "{percent}% Bonus",
      },
    },

    bonusBanner: {
      title: "GREAT BONUS FOR EVERY DEPOSIT",
      description: "Available for all players",
      before: "Before",
    },

    how: {
      subTitle: {
        _1: "What is USDT?",
        _2: "How to unlock USDT?",
        _3: "How to claim unlocked USDT?",
        _4: "Can USDT be exchanged to any other currency?",
        _5: "What is special about USDT?",
      },
      subText: {
        _1: `(USD Tether) is a stablecoin available on Pasino, where:  1 USDT = 1 USD.  Stablecoins are cryptos pegged to a fiat currency, say a USD. You can use these as stores of values or units of account.`,
        _1b: " Tether or USDT is one of the first stablecoins and most popular, backed by US Dollars that’s off-chain, which means that the collateral is in a real-world location controlled by a third party. At Pasino.com, the USDT is one of the accepted cryptos that players can use to play casino games, send tips, join a coin drop, and send or sell using Rain.",
        _2: `You can earn locked USDT through specific bonuses, such as a deposit bonus and lucky spin.`,
        _3: `Unlocking your USDT at Pasino is fast and easy!`,
        _3b: `It’s essentially the same as Rakeback & will unlock proportionally by placing real money bets. Here’s the process on how you can unlock the crypto and start enjoying its perks:`,
        _4: "As you wager, your USDT treasure box will fill with unlocked USDT.",
        _5: `Once you fill your treasure box with a minimum of 5 USDT, click ‘Claim,’ and the unlocked USDT will be transferred directly to your USDT balance.`,
        _6: "That’s it! Your USDT is now immediately available to use!",
        _7: `Sure! The most exciting thing is that the value of 1 USDT is bound to
        the price of 1 USDT, and you can {pasinoswap} Pasino into other
        currencies at any time.`,
        _8: `You can enjoy up to 10% annualized rate of return when you store your
        USDT in {vault}. So enjoy collecting Pasino!`,

        add_7: "Pasinoswap",
        add_8: "Vault Pro",
      },
      formula: {
        unlock: "Unlock",
        wager: "wager",
        amount: "amount",
      },
    },
  },

  hotKeys: {
    title: "Hotkeys",

    buttons: {
      a: "Half bet amount",
      s: "Double bet amount",
      space: "Place bet",
    },
    switch: {
      on: "Enabled",
      off: "Disabled",
    },
  },
  sound: {
    title: "Sound System",
    volume: "Volume",
    gameEffects: "Game Effects",
    backgroundMusic: "Background Music",

    switch: {
      on: "On",
      off: "Off",
    },
  },

  notification: {
    tabs: {
      reward: "Rewards",
      notification: "Notifications",
    },

    content: {
      received: "Received",
      claim: "Claim",
      no_challenges: "There are no challenges available right now.",
    },
  },

  level: {
    header: {
      title: "LEVEL DASHBOARD",
      text: `Welcome to the level dashboard! We at Pasino know what you need as a true gambler, so
        we define for each level bonuses and perks which you will unlock on your journey with us.
      `,
    },
    slider: {
      m_01: {
        title: "Steel",
        text: `Welcome to Pasino, young rookie. Your journey just has started but we already got several rewards
        for you! Start to place your first bets and listen to the melody of the rewards!`,
      },
      m_02: {
        title: "Bronze",
        text: `You made it far but your journey has still a long way to go, young player. Congratulations on your first
        steps in the wonderful world of Pasino. The Bronze League is waiting for you!`,
      },
      m_03: {
        title: "Silver",
        text: `Congratulations! Your baby steps are over! Silver League is the one where things get funny! Several
        bonuses and perks are waiting for you to get unlocked. Don't waste your time!`,
      },
      m_04: {
        title: "Golden",
        text: `Whoho, see here who made it to the Gold League... You are one of the champions who made it here
        but you still have a long way to pass. Collect your bonuses and gamble your way to the TOP!`,
      },
      m_05: {
        title: "Platinum",
        text: `Who would think about that... The Gold League wasn't that hard for you, right? Let's test if you are
        one of these players who are unstoppable. Welcome to the Platinum League!`,
      },
      m_06: {
        title: "Platinum Elite",
        text: `Surprise, surprise... It really seems like you are unstoppable. We got the right things loaded for you!
        The Platinum Elite League is full of BIG bonuses which will boost your wallet!`,
      },
      m_07: {
        title: "Diamond",
        text: `Only very few people come here to the Diamond League. You are one of them and that will be
        rewarded with the highest bonuses ever! You are the Leader of the Pasino World!`,
      },
    },
    userData: {
      current: "Your current XP",
      total: "Total wager",
      reachTo: {
        reach: "Reach",
        to: "to",
      },
    },
    vip: {
      title: "HOW CAN I LEVEL UP?",
      text: `Place Bets in any crypto currency we offer and earn for each US$1 you wager 1 XP. Check
        out the full details and requirements for each level on “More Details”
      `,
      button: "About Levels",

      table: {
        header: {
          star: "Emblem",
          level: "Level",
          bonus: "Bonus",
          credit_amount: "Credit Amount",
          total: "Total Wagered Required",

          xpAmount: "XP Amount",
          xpProgress: "XP Progress Bar",
        },
        content: {
          progress: {
            ok: "Complete",
            nOk: "Not Started",
          },
        },
      },
    },
    user: {
      title: "User Information",

      statistics: {
        name: "Statistics",
        table: {
          wins: "Profit (ATH)",
          bets: "Total Bets",
          wagered: "Total Wagered",
        },
      },
    },
    rights: {
      title: "YOUR UNLOCKED LEVEL PERKS",

      unlocked: "Available",
      locked: "Unavailable",

      cards: {
        bonus: {
          title: "Bonus Codes",
          text: "Create or Redeem bonus codes in any cryptocurrency we offer!",
        },
        rakeBack: {
          title: "RakeBack",
          text: "Get a refund of up to 0.75% on every bet you place!",
        },
        rain: {
          title: "Rain",
          text: "Collect free coins via rains or let it rain on players in the chat!",
        },
        boxes: {
          title: "Loot Boxes",
          text: "Each bet has a chance to drop a loot box.  Loot boxes contain a unique bonus that can be looted for a great reward!",
        },
        tip: {
          title: "Tip",
          text: "Send tips to friends and help them in difficult times!",
        },
      },
    },
    exclusive: {
      title: "Exclusive LVL Benefits",

      cards: {
        program: {
          title: "Unique VIP Transfer Program",
          text: "Viverra imperdiet rhoncus, in magna nulla facilisis egestas nunc, vel. Scelerisque pretium nunc pellentesque luctus posuere scelerisque viverra leo. Mi interdum faucibus vitae sodales. Lectus aliquam imperdiet non et pellentesque scelerisque aliquam varius metus. Risus sapien ac eu.",
        },
        host: {
          title: "Exclusive VIP Host",
          text: "Tellus senectus id lacus scelerisque phasellus. At velit ac pellentesque non tincidunt cum viverra scelerisque tincidunt. Enim id a justo, feugiat et dictum. Sit quam orci convallis pharetra. Erat praesent amet adipiscing ante sed nunc sit consectetur molestie. Vitae odio augue elit quis ut.",
        },
        host_2: {
          title: "Exclusive VIP Host",
          text: "Nulla at leo aliquet mauris vel arcu, posuere nullam elementum. Elementum eros id arcu aenean. Pellentesque in neque nullam malesuada quis posuere pellentesque. Netus odio malesuada massa pretium fermentum, sed eu, massa rhoncus. Nunc viverra in euismod lorem.",
        },
        treasure: {
          title: "Secret Treasure",
          text: "Eu nisi, vitae vel volutpat ullamcorper vitae. Libero ornare euismod at dictumst dignissim sed ut ullamcorper. Semper quis orci ut nullam est. Dolor tellus leo mi sollicitudin in. Eget eget est est rutrum suspendisse gravida urna. Diam sed venenatis, nulla nunc. Mattis volutpat.",
        },
        coins: {
          title: "Free Coins Every Day",
          text: "Nec, id eu tempor, morbi tincidunt. Pellentesque leo amet consequat diam consequat. Diam parturient sed mi lorem leo. Fames id vitae egestas congue vitae mi, hac. Tortor quis ut egestas proin eu. Ut risus interdum eu eu mauris. Dignissim dapibus malesuada venenatis elit pretium.",
        },
        bottle: {
          title: "SVIP's Genie In A Bottle",
          text: "Ultrices ipsum proin blandit lacus dignissim adipiscing lacinia. Tortor, faucibus laoreet euismod arcu nam bibendum dictum. Enim, non amet sem elit urna posuere. Nulla lectus sed commodo vel eget cursus sit morbi. Aliquam suspendisse urna sit et praesent. A mauris.",
        },
        trip: {
          title: "SVIP Trip",
          text: "Vitae ac magna cursus ultrices justo, scelerisque enim. Mattis integer tortor posuere ut tristique porttitor. Pretium, aenean volutpat lacus augue viverra sed enim pretium. Pellentesque elementum sem enim integer. Congue quis non tortor sit arcu eget vitae molestie fringilla ut.",
        },
        suite: {
          title: "SVIP Life Is Suite",
          text: "Id accumsan gravida sed sit pellentesque aliquet quam non. Faucibus mollis magna ut placerat lacus, suspendisse. Nisi at elit eget faucibus arcu aliquam in duis urna. Tortor, sit amet integer sit nisl. Volutpat imperdiet leo urna sit dui. Libero in sed enim varius commodo quis. Posuere.",
        },
        party: {
          title: "SVIP Party",
          text: "Quam nunc duis ultricies ullamcorper at. Lectus facilisis donec sollicitudin lorem morbi scelerisque. Proin praesent nec feugiat morbi eu aliquam. Velit eget facilisis aliquam habitant euismod. Sodales ac sit nulla eu dignissim a sed sapien. Quis leo orci lectus aliquet sit. Magnis.",
        },
      },
    },
  },
  rules: {
    rules: "Rules",
    rulesTitle: "Rules:",
    rule1:
      "1. Our goal is to be a fun place to play, where everyone can feel comfortable. As such, we will not tolerate vulgarity. All our players' chat needs to be clean and respectful.",
    rule2:
      "2. Please note: Game Chat is a privilege, not a right. It does NOT affect your ability to earn and/or stop you from playing at Pasino.",
    rule3:
      "3. The game chat is a community chat that you are logged into once in the site.",
    rule4:
      "4. The game chat is moderated to prevent spamming, griefing, trolling, begging, unsought links, excessive behavior and violations of rights and freedoms.  The game chat is easier with the help of our moderators and ALL content is stored on our servers for review at any time.",
    rule5:
      "5. There are a number of chat rules with penalties for each type of violation depending on the severity and recurrences. Anytime we conduct a review, we consider the user's account history and previous infractions.",
    rule6:
      "6. WARNINGS: If you are warned. Please do not keep pushing the mods, as after so many chances you will be MUTED.",
    rule7:
      "7. MUTES: If you are muted, it is max 120 hrs. Consider this a cooldown period.",
    rule8:
      "8. ACCOUNT SUSPENSION: This should be self-explanatory. These are permanent. Do not get on the ban list as this includes complete lockdown of the account.",
    rule8Note:
      "NOTE: Warnings and Mutes do not affect you playing and collecting. It only blocks you from game chat.",
    chatRules: "Chat Rules:",
    rule9:
      "9. Profanity/Abuse: Profane, vulgar, racist or abusive/insulting chat. Do not engage in retaliatory chat, as this will likely result in sanctions.",
    rule10:
      "10. Begging/solicitation: Asking for coins, rains and/or but not limited to bonus codes (whether play money or real money).",
    rule11:
      "11. Fairness/System Advantaging: Chatting intentionally to recieve rewards without contributing.",
    rule12:
      "12. Flooding/Spamming: The sending of multiple and frequent messages through chat in order to drown out legitimate chat.",
    rule13:
      "13. Non-English chat (some exceptions apply): We are aware that our players are from different backgrounds and that for many of them English is not their first language. However, at this time our policy is that English is the only language allowed in the English chat. If English is not your first language, please switch to the appropriate channel.",
    rule14:
      "14. Commercial Use: It is strictly prohibited to use chat for any commercial gain whatsoever, including making any statements which promote any third-party services or products.",
    rule15:
      "15. Pinging/Provoking: Repeated ping (calling on a user) for nonsense and or direct including indirect provoking.",
    rule16:
      "16. Buy/steal existing users: Offering incentives to re-register under your referral.",
    rule17:
      "17. Malicious behaviour: Chat cannot be used for any malicious or offensive behaviour, including but not limited to collusion, fraud or spam. Users shall not make statements about our site that are untrue or would reasonably be considered to be derogatory or critical. Users should be generally aware of the feelings of other customers and staff, and should act in a respectful manner.",
    rule18:
      "18. Stalking/harassment: To harass, abuse, intimidate and intentionally disrupt the game is prohibited. We will investigate any such reports. When sending a report of this kind always include the user's name of the person reported and approximate dates and times, and video/photo proof as well as the subject Chat Stalking Complaint - Attn: Stalking Team.",
    rule19:
      "19. When we examine a chat complaint, we look at all chat involved, as well as context. We take appropriate action in all cases where a player is in violation of chat rules.",
    rule20:
      "20. We reserve the right to change, alter and/or update the rules as needed, thus if a situation arises where staff have to get involved REMEMBER staff have the final say.",
    rule21:
      "21. If reported players are still chatting, then they were either considered to be not abusive, or given a warning, rather than having their chat suspended.",
    rule22:
      "22. Keep religion/politics chat to a minimum. The chatroom is made to help the community find players, reconnect with lost players, discuss games and ask for assistance on issues regarding the site. Starting topics on religion and politics is just asking for trouble/arguments.",
    rule23:
      "23. Do not try to get around the filters. If you continuously try, you will be removed from chat.",
    rule24:
      "24. Do not argue publicly with the mods. If you donвЂ™t like how they moderated something or are concerned with how something was done, talk with them privately. If it still goes unresolved, send an email to our support team.",
    rule25: "25. No hate speech of any kind is allowed.",
    rule26:
      "26. No advertising of outside games or websites that do not pertain to our platform unless expressed permission by staff is granted.",
    remember:
      "Remember: Moderators can't be around all the time and even when they show up, you can't have them try to fix something that happened BEFORE they arrived. Chat is an instant thing, it's not like posting on a forum that sticks around.",
    rememberNote:
      "While we appreciate you wanting to help out in chat, please do not backseat moderate. If you encounter some problems in chat, just leave it to the moderators to deal with.",
  },

  roulette: {
    reload: "Clear chips",
    chipValue: "Chip value",
    totalBet: "Total Bet",
  },
  blackjack: {
    labels: {
      amount: "Amount",
      min: "Min",
      double: "2X",
      half: "1/2",
      max: "Max",
      insurance_question: "Insurance?",
    },
    buttons: {
      bet: "Deal",
      hit: "Hit",
      split: "Split",
      stand: "Stand",
      double: "Double",
      insurance: "Insurance",
      no: "No",
    },
    hotkeys: {
      space: "Deal",
      q: "Hit",
      w: "Stand",
      e: "Split",
      r: "Double",
      t: "Accept insurance",
      y: "No insurance",
      a: "Half bet amount",
      s: "Double bet amount",
      d: "Min bet amount",
    },
  },
  towers: {
    hotkeys: {
      q: "Select the first cell",
      w: "Select the second cell",
      e: "Select the third cell",
      r: "Select the fourth cell",
    },
  },
  feyorraHouseEdge: {
    header: {
      title: "Feyorra House Edge Share",
      text: "Verify your Feyorra Stake and earn a share from the house edge!",
    },
    data: {
      connectMetaMask: "Connect with Metamask",
      stake1: "Stake > 1MM FEY = 50% on the Reward",
      stake2: "Stake > 5MM FEY = 75% on the Reward",
      stake3: "Stake > 10MM FEY = 100% on the Reward",
      yourShare: "Your share",
      feyOnPasino: "FEY verified on pasino",
      totalFeyOnPasino: "Total FEY Verified on Pasino",
      unlinkAddress: "Unlink Address",
      dailyWageringGraph: "Daily Wagering Graph",
      note: "Your estimated earnings may change as people link or unlink their addresses",
    },
    payoutsTable: {
      payouts: "Payouts",
      coin: "Coin",
      payoutToday: "Estimated Earnings (Today)",
      earnedTokens: "Total Wagered (Today)",
    },
    historyTable: {
      payoutHistory: "Payout history",
      date: "Date",
      share: "% share",
      totalPayout: "Total Payout",
      bonus: "Included Bonus Amount",
      coinType: "Coin type",
      showAll: "Show All",
      showLess: "Show Less",
    },
    errors: {
      metaMaskNotInstall: "MetaMask not installed",
    },
  },
  feyorraUtilities: {
    feyorraUtilities: "Feyorra Utilities",
    utilitiesText:
      "Feyorra is our utility coin here in Pasino and you can earn great rewards with it! Stake it and earn a passive income or just increase your Feyorra bag with Wagermining!",
    houseEdgeSharing: "House Edge Sharing",
    edgeSharingText:
      "Verify your FEY stake here on pasino and earn a passive income in all cryptocurrencies we offer!",
    curSharing: "Currently Sharing: ",
    totalVolume: "{percent}% of total volume",
    wagerMining: "Wager Mining",
    wagerMiningText:
      "Feyorra distributes 40% of the initially supply via wagermining. Every USD$1 you wager in any cryptocurrency rewards you with 0.07 FEY",
    moreInformation: "More Information",
  },
  tip: {
    tip: "Tip",
    sendTip: "Send Tip",
    success: "The payment was sent successfully!",
  },
  socialMessages: {
    orContinueWith: "Or continue with",
  },
  fortune: {
    switch: {
      auto_hold_on: "Auto hold on",
      off: "Off",
    },
    buttons: {
      bet: "BET",
      rebet: "REBET",
      deal: "DEAL",
      max: "Max",
      min: "Min",
    },
    inputLabel: "Amount",
  },

  lottery: {
    banners: {
      buy: "Buy Tickets",
      lottery: "Lottery",
      jackpot: "Jackpot",
      draw: "Next Draw in",
      id: "Current round ID: ",
      tickets: "Purchased tickets for this round: ",
      price: "Ticket price of US$0.1",
    },
    tabs: {
      tickets: "My Tickets",
      wins: "My Winnings",
      history: "History",
    },
    gameData: {
      noDataTicket: "You have no tickets!",
      noDataWins: "Oops! There is no data yet!",
      gameID: "Game ID",
      buy: "Buy ticket now",
      more: "More",
      hide: "Hide",
      claimAll: "Claim All",
    },
    howToPlay: {
      generalitle: "How to Play",
      title1: "Buy Tickets",
      title2: "Wait for the Draw",
      title3: "Check for Prizes",
      text1: "Buy ticket with $0.1, and choose numbers for tickets.",
      text2: "Wait for the draw at 00:00 UTC+0 daily.",
      text3:
        "Once the draw is over, come back to this page and check your prize.",
    },
    rules: {
      generalitle: "Lottery Prizes",
      matches: "Matches",
      numbers: "Numbers",
      prize: "Prize",
      jackpotBall: "5 Numbers Jackpot Ball",
      fiveNumbers: "5 Numbers",
      fourNumbers: "4 Numbers",
      threeNumbers: "3 Numbers",
      twoNumbers: "2 Numbers",
      oneNumbers: "No Numbers",
      ticket: "+1 ticket",

      firstSectionTitle: "Lottery Instructions:",
      secondSectionTitle: "Winning Prize Details:",
      firstSectionText:
        "A provably fair algorithm is used to draw the lottery prizes.\n Prizes are drawn at 00.00 UTC+0 every day.\n You can buy a ticket for $0.1. The sale of tickets stop at 23:55 UTC+0 every day.\n The player chooses six numbers for each ticket, the first five are from 1 to 36, and the last one is from 1 to 10.\n You can choose numbers manually or automatically.\n  Each draw produces six numbers.\n  The more numbers you match in the first five numbers, greater prize you will win.",
      secondSectionText:
        "All six numbers matched: $100,000 prize. In case you are not the only winner, you may need to share the prize equally with others.\n Five numbers matched, and the sixth number is missed: Each ticket gets a $3,000 prize.\n Four of the first 5 numbers matched: Each ticket gets a $20 prize.\n Three of the first 5 numbers matched: Each ticket gets a $1 prize.\n If all six numbers are missed, the lottery will be reserved for free, and continue participating in the next day's draw.",
      fair: "Provably Fair",
    },
    wins: {
      numbers: "Ticket Numbers",
      id: "Game ID",
      tickets: "Tickets",
      prize: "Prize",
    },
    tickets: {
      numbers: "Ticket Numbers",
      ticket: "Ticket",
      prize: "Prize",
      claim: "Claim",
    },
    history: {
      luckyNumbers: "Lucky\n Numbers",
      numOfTickets: "Number of tickets",
      totalSold: "Total tickets sold this round ",
      total: "Total ",
      winner: "Winner Name",
      numbers: "Numbers",
      matches: "Matches",
      prize: "Prize",
    },
    modals: {
      title: "Buy Lottery Tickets",
      payWith: "Pay with",
      number: "Number of tickets",
      totalCost: "Total Cost:",
      ticket: "Ticket",
      auto: "Auto Generate",
      manual: "Manual Select",
      autoMobile: "Auto",
      manualMobile: "Manual",
      optional: "5 digits",
      random: "Random",
      jackpotBall: "1 Jackpot Ball",
      prompt: "Please choose 6 numbers to take part in the PASINO.COM Lottery!",
      buy: "Buy Tickets",

      min: "Min",
      max: "Max",

      helpPart1:
        "PASINO.COM Lottery is provably fair which means you can examine the results using ",
      helpPart2:
        " and following data. The whole data including the file with purchased tickets is available only when ticket sales stopped.",
      onlineVerifier: "online verifier",
      fairTitle: "Lottery Fairness",
      serverHash: "Server Seed (Hashed)",
      serverHashHelp: "SHA-256 of server seed",
      serverSeed: "Server Seed",
      serverSeedPlaceholder: "Reveal after draw",
      serverSeedHelp: "A server randomly genereted string",
      stopBlock: "Stop Block",
      stopBlockPlaceholder: "Reveal every day at 00.00 UTC+0",
      stopBlockHelp: "The ETH blockchain height at 23:55 UTC+0 of draw date",
      clientSeedBlock: "Client Seed Block",
      clientSeedBlockPlaceholder: "0",
      clientSeedBlockHelp:
        "The ETH blockchain height after 10 blocks from stop block",
      clientSeedHash: "Client Seed (Block Hash)",
      clientSeedHashPlaceholder: "Reveal after Client Seed Block generated",
      clientSeedHashHelp: "Target block of ETH blockchain",
      verify: "Verify",

      provablyText1:
        "Lottery is drawn in 5+1 balls rule, with five regular balls taken from 36 numbers and a jackpot ball taken from 10 numbers.",

      provablyText2:
        "Server seed: Each game generates a new server seed and publishes the string after Hash256. /n Client Seeds: Get the ETH height at the end of the daily ticket purchase deadline, and use Hash after 10 blocks as the client seed.",
      provablyText3:
        "First, use HMAC_SHA256 to calculate the hash value, which gives us a 64-bit character hex string:",
      provablyHash: "hash = HMAC_SHA256 (clientSeed, serverSeed)",
      provablyText4:
        "Then, take the 8 characters of hash and convert it to an int32 value. We divide the converted value by the 0x100000000, multiply by the number of balls, and the number is the winning position, according to which the corresponding ball is obtained.",
    },
  },

  mirrors: {
    title: "The Official Pasino Mirror List",
    text: "Can't access Pasino? Try one of these mirror sites below.",
    visit: "Visit Now",

    mirrors: "Mirrors",
  },
  nftPages: {
    search: "SEARCH",
    marketplace: {
      marketplace: "marketplace",
      myInventory: "my inventory",
      searchPlaceholder: "Search in marketplace",
      currentBid: "Current Bid",
      nothingToShow: "Nothing to show",
    },
    myInventory: {
      searchPlaceholder: "Search in my Inventory",
      allItems: "All Items",
      onSale: "On Sale",
      place: "Sell",
      withdraw: "Withdraw",
      cancel: "Cancel sale",
      decorate: "Decorate",
      undecorate: "Undecorate",
      redeem: "Redeem",
    },
    NFTDetails: {
      description: "Description",
      createdBy: "Created by",
      about: "About",
      details: "Details",
      address: "Contract Address",
      comments: "Comments",
      offers: "Offers",
      currPrice: "Current price",
      buyNow: "Buy Now",
      makeOffer: "Make Offer",
      salesHistory: "Sales History",
      ownedBy: "Owned by",
      favorites: "favorites",
      moreCollection: "More From This Collection",
      viewCollection: "View collection",

      price: "Price",
      USDPrice: "USD Price",
      from: "From",

      sortOrder: "Sort Order",
      addComments: "Add a comment...",
      send: "Send",
      cancel: "Cancel",
      answer: "Answer",
      viewAnswers: "View Answers",
      newFirst: "New ones first",
      popularFirst: "Popular first",
      noActivity: "No data yet!",
      backgroundsBanner: {
        title: "Backgrounds",
        text: "Decorate your profile with a fancy background and let other players be amazed",
      },
      framesBanner: {
        title: "Frames",
        text: "Frame your profile picture with a unique animation ",
      },
      emblemsBanner: {
        title: "Emblems",
        text: "Collect all Emblems and be the next Pasino NFT King",
      },
    },
    offers: {
      incoming: "Incoming offers",
      outbound: "Outbound offers",
      filterTitle: "Filter by status",
      all: "All",
      PENDING: "Pending",
      ACCEPTED: "Accepted",
      REJECTED: "Rejected",
      CANCELED: "Canceled",

      suggestedPrice: "Suggested Price",
      USDPrise: "USD Price",
      username: "User Name",
      status: "Status",
      accept: "Accept",
      reject: "Reject",
      cancel: "Cancel",

      prev: "Previous",
      next: "Next",
    },
    salesHistory: {
      select1: "Last 7 Day",
      select2: "Last 14 Day",
      select3: "Last 30 Day",
      select4: "Last 60 Day",
      select5: "Last 90 Day",
      select6: "Last Year",
      select7: "All Time",
    },
    filter: {
      title: "Filters",
      sortBy: "Sort by",
      popular: "Popular",
      status: "Status",
      buyNow: "Buy Now",
      onAuction: "On Auction",
      new: "New",
      hasOffer: "Has Offer",

      price: "Price",
      apply: "Apply",
      dollarTitle: "United States Dollar (USD)",
      min: "Min",
      max: "Max",
      to: "to",

      onSaleIn: "On Sale In",
      all: "All",

      searchHint: "Type 3 or more characters",
      searchMessageLoading: "Searching...",
      searchMessageError: "Nothing found",
    },
    modals: {
      quick: "Quick sale",
      auction: "On auction",
      placeItem: "Place Item",
      placeNft: "Place NFT",
      confirm: "Confirm offer",
      balance: "Balance",
      sure: "Are you sure?",
      yes: "yes",
      no: "no",

      withdrawTitle: "Withdraw",
      deposite: "Deposit",
    },
  },

  welcome: {
    title: "Welcome Bonus",

    text: {
      _1: "Already sent to your balance.",
      _2: "Glad to have you onboard.",
      _3: "Wish you super luck and have fun!",
    },
  },
};

export default messages as RawLocalesList;
