import { isDevelop, track } from "utils";

const LazyComponentLoader = (
  lazyComponent: Promise<any>,
  attemptsLeft: number = 3,
): Promise<any> => {
  return new Promise((resolve, reject) => {
    lazyComponent.then(resolve).catch((error) => {
      setTimeout(() => {
        if (attemptsLeft === 1) {
          track()?.errors(error, "LazyComponentLoader");
          reject(error);
          if (!isDevelop()) window.location.reload(true);
          return;
        }
        LazyComponentLoader(lazyComponent, attemptsLeft - 1).then(
          resolve,
          reject,
        );
      }, 1500);
    });
  });
};

export default LazyComponentLoader;
