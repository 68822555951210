import React, { FC, forwardRef, Suspense } from "react";
import LazyComponentLoader from "config/LazyComponentLoader";

function createSuspenseComponent<Props extends {}, Ref = undefined>(
  imp: Promise<any>,
  isForwardRef: true,
  fallback?: FC | null,
): React.ForwardRefExoticComponent<
  React.PropsWithoutRef<Props> & React.RefAttributes<Ref>
>;

function createSuspenseComponent<Props extends {}, Ref = undefined>(
  imp: Promise<any>,
  isForwardRef?: false,
  fallback?: FC | null,
): React.FC<Props>;

function createSuspenseComponent<Props extends {}, Ref = undefined>(
  imp: Promise<any>,
  isForwardRef?: boolean,
  fallback: FC | null = null,
) {
  const LoadComponent: any = React.lazy(() => LazyComponentLoader(imp));

  const Component: FC<Props> = (props) => (
    <Suspense fallback={fallback}>
      <LoadComponent {...props} />
    </Suspense>
  );

  const RefComponent = forwardRef<Ref, Props>(({ ...rest }, ref) => {
    return (
      <Suspense fallback={fallback}>
        <LoadComponent {...rest} ref={ref} />
      </Suspense>
    );
  });

  return isForwardRef ? RefComponent : Component;
}

export default createSuspenseComponent;
