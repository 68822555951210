import { Auth } from "providers/Auth/Context";
import { isNumber, isObject, track } from "utils";

const persistKey = "auth";

export const instanceOfAuth = (value: unknown): value is Auth => {
  if (
    value &&
    isObject(value) &&
    "isAuthenticated" in value &&
    typeof value?.isAuthenticated === "boolean"
  ) {
    if ("token" in value && typeof value?.token !== "string") {
      return false;
    }
    if ("expirationTime" in value && typeof value?.token !== "string") {
      return false;
    }

    return true;
  }
  return false;
};

export const getAuth = () => {
  let innerAuth: any;

  try {
    const localAuth = localStorage?.getItem(persistKey);
    if (localAuth) {
      innerAuth = JSON.parse(localAuth);
    }
  } catch (error) {
    const message = `
      ${error}
      utils > auth > getAuth
      persistKey: ${persistKey},
      innerAuth: ${innerAuth}`;

    try {
      track();
    } catch {
      console.log("🚀 trackError", error, persistKey);
    }
  }

  if (instanceOfAuth(innerAuth)) {
    return innerAuth;
  }

  return { isAuthenticated: false };
};

export const getToken = () => {
  const auth = getAuth();
  const token = auth?.token;

  if (typeof token === "string") {
    return token;
  }

  return undefined;
};

export const isTokenExpired = () => {
  const auth = getAuth();
  const expirationTime = auth?.expirationTime;

  if (isNumber(expirationTime) && Date.now() <= expirationTime) {
    return false;
  }

  return true;
};
