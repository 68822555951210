export const toLocaleString = (
  value: number | string,
  maxDigits = 0,
  minDigits?: number,
) => {
  return Number(value).toLocaleString("en-US", {
    maximumFractionDigits: maxDigits,
    minimumFractionDigits: minDigits || maxDigits,
  });
};
