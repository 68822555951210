import messages_en from "./en";

export interface RawLocalesList {
  [key: string]: string | RawLocalesList;
}

export interface LocalesList {
  [key: string]: string;
}

export enum localesLanguages {
  en = "en",
  de = "de",
  es = "es",
  tr = "tr",
  ru = "ru",

  id = "id",
  jp = "jp",
  pl = "pl",
}

export const localesRTLLanguages: Record<localesLanguages, boolean> = {
  en: false,
  de: false,
  es: false,
  tr: false,
  ru: false,

  id: false,
  jp: false,
  pl: false,
};

const parseLocalesList = (
  object: RawLocalesList,
  parentKey?: string,
): LocalesList => {
  const keys = Object.keys(object);

  return keys.reduce<LocalesList>((acc, key) => {
    const value = object[key];
    const objectKey = parentKey ? `${parentKey}.${key}` : key;

    if (typeof value === "string") {
      return {
        ...acc,
        [objectKey]: value,
      };
    }

    return {
      ...acc,
      ...parseLocalesList(value, objectKey),
    };
  }, {});
};

const locales: Record<localesLanguages, LocalesList | null> = {
  en: parseLocalesList(messages_en),
  de: null,
  es: null,
  tr: null,
  ru: null,

  id: null,
  jp: null,
  pl: null,
};

export const languageImport = async (
  language: localesLanguages,
  setMessages: (value: LocalesList | null) => void,
) => {
  const lang = await import(`./${language}`);
  const locale = parseLocalesList(lang.default);
  locales[language] = locale;

  setMessages(locale);
};

export const defaultLocale = localesLanguages.en;

export default locales;
