import { ListenerEvents } from "types/general";

export const createListeners = <E extends ListenerEvents>(events: E[]) => {
  const addListeners = (func: (event: Event) => void) => {
    events.forEach((type) => {
      window.addEventListener(type, func);
    });
  };

  const removeListeners = (func: (event: Event) => void) => {
    events.forEach((type) => {
      window.removeEventListener(type, func);
    });
  };

  return {
    addListeners,
    removeListeners,
  };
};
