import { ErrorTags } from "./type";
import { prepareError } from "./prepareError";
import { FirebaseLogger, SentryLogger } from "./loggers";

const isDevelopment = process.env.NODE_ENV === "development";

const logger = {
  default: new SentryLogger(),
  critical: new FirebaseLogger(),
};

export const track = (critical?: boolean) => {
  try {
    const trace = new Error().stack;

    if (isDevelopment) {
      return {
        errors: (err: any, name: string, tags?: ErrorTags) => {
          console.log(
            `%c ~~~~~~~~~~~~~~~~~~~~~~~~ >>>>>> TRACK ${
              critical ? "CRITICAL" : ""
            } ERROR <<<<<<<< ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ `,
            "background: #0f0; color: #000; border-radius: 5px",
            "\n",
            ` >> ${name} `,
            "\n\n >> Error: \n",
            err,
            "\n\n >> data: \n",
            tags,
            "\n\n *** \n",
            trace,
          );
        },
      };
    }

    if (!critical) {
      if (!logger.default.isInit()) {
        logger.default.init();
      }
    } else {
      if (!logger.critical.isInit()) {
        logger.critical.init();
      }
    }

    return {
      errors: (err: any, name: string, tags?: ErrorTags) => {
        try {
          err = prepareError(err, trace);
          if (!critical) logger.default.error(err, name, tags);
          else logger.critical.error(err, name, tags);
        } catch (error) {
          console.error(">> Error on errorTracker on errors(): ", error);
        }
      },
    };
  } catch (error) {
    console.error(">> Error on errorTracker: ", error);
  }
};

//@ts-ignore
window.createCustomError = (
  critical = false,
  msg = "some custom error",
  tags?: ErrorTags,
) => {
  track(critical)?.errors(msg, "custom", tags);
};
