import { createIntervals } from "./createInterval";
import { createTimeouts } from "./createTimeout";
import { track } from "./track";
import { isDevelop } from "./isDevelopment";

const isDev = isDevelop(true);

const devLog = (callBack: () => void) => {
  return;
  if (isDev) {
    callBack && callBack();
  }
};

const intervals = {
  waitTime: 0,
  reconnectionTime: 200,
};

const closeSocketUserReason = "close_connection";

export class Socket {
  ws: WebSocket | undefined;
  errorFunctions: { [key: string]: Function } = {};
  address: string;
  openFunction: Function | undefined;
  restartFunction: Function | undefined;
  listenerFunctions = {
    listener: (data: any) => {},
  };

  timeouts = createTimeouts();
  intervals = createIntervals();

  constructor(address: string) {
    this.address = address;
  }

  status() {
    if (this.ws) {
      return this.ws.readyState;
    } else {
      return 0;
    }
  }

  send(obj: object, onSend?: () => void) {
    let closer = false;
    devLog(() => {
      if (this.ws?.readyState !== 1) {
        console.log(
          "can't socket send. Socket:",
          this,
          "\nsendData:",
          obj,
          "\nreadyState: ",
          this.ws?.readyState,
        );
      }
    });

    const interval = this.intervals.pushInterval(() => {
      const status = this.ws?.readyState;
      if (status === 1) {
        this.ws && this.ws.send(JSON.stringify(obj));
        closer = true;
        onSend && onSend();
      }

      if (closer) {
        clearInterval(interval);
      }
    }, intervals.waitTime);
  }

  dispatch(func: (data: any) => void) {
    this.listenerFunctions.listener = func;
  }

  errorDispatch(name: string, func: Function) {
    this.errorFunctions[name] = func;
  }

  listener() {
    if (this.ws) {
      this.ws.onopen = () => {
        this.timeouts.clearTimeouts();
        devLog(() => {
          console.log("socket onOpened. Socket:", this);
        });
        if (this.openFunction) {
          this.openFunction();
        }
      };

      this.ws.onmessage = (event) => {
        try {
          const serverData = JSON.parse(event.data) || {};
          this.listenerFunctions.listener(serverData);
        } catch (error) {
          const message = `
            socket > listener > onmessage
            MessageEvent: ${JSON.stringify(event.data)}`;

          track()?.errors(error, "socket > listener > onmessage", {
            custom: {
              message,
            },
          });
        }
      };

      this.ws.onclose = (ev) => {
        this.intervals.clearIntervals();
        devLog(() => {
          console.log("socket onClose. Socket:", this);
        });

        for (const [name, func] of Object.entries(this.errorFunctions)) {
          func(ev);
        }

        if (ev.reason !== closeSocketUserReason) {
          devLog(() => {
            console.log("socket begin restarted. Socket:", this);
          });

          if (process.env.REACT_APP_URL !== "https://api.pasino.com") return;

          this.timeouts.pushTimeout(() => {
            this.ws = new WebSocket(this.address);
            devLog(() => {
              console.log("socket restarted. Socket:", this);
            });
            this.listener();
          }, intervals.reconnectionTime);
        } else if (this.restartFunction) {
          this.restartFunction();
        }
      };
    }
  }

  open(func?: Function) {
    devLog(() => {
      console.log("socket open. Socket:", this, "open callback:", func);
    });
    if (!this.ws || this.ws.readyState !== 1) {
      this.ws = new WebSocket(this.address);

      devLog(() => {
        console.log("socket opened. Socket:", this, "open callback:", func);
      });
    }

    if (func) {
      this.openFunction = func;
    }
  }

  close(reason = "", isFinally = false) {
    console.log("Socket closed. Reason: ", reason);

    devLog(() => {
      console.log("socket close. Socket:", this, "reason:", reason);
    });

    if (this.ws) {
      if (isFinally) {
        this.restartFunction = undefined;
        Object.keys(this.errorFunctions).forEach(
          (key) => delete this.errorFunctions[key],
        );
      }

      const close = (socket: WebSocket) => {
        try {
          if (socket.readyState === 1) {
            socket.close(1000, isFinally ? closeSocketUserReason : reason);
          } else {
            setTimeout(() => {
              close(socket);
            }, 100);
          }
        } catch (e) {
          track()?.errors(e, "close socket", {
            custom: {
              reason,
              isFinally,
            },
          });
        }
      };
      close(this.ws);
      this.ws = undefined;
    }
  }
}

export const crashSocket = new Socket(
  `${process.env.REACT_APP_CRASH_GAME_WS_URL}`,
);
export const fortuneSocket = new Socket(
  `${process.env.REACT_APP_FORTUNE_GAME_WS_URL}`,
);

export const chatSocket = new Socket(`${process.env.REACT_APP_CHAT_WS_URL}`);
