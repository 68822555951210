import { Coin } from "hooks";
import { toDecimal, isNumberString } from ".";

const DECIMAL_ZERO = toDecimal("0");

export const getUsdValue = (coin: Coin, coinAmount: string | number) => {
  const coinPrice = String(coin?.price);
  return coin.coin &&
    coin.coin !== "GEM" &&
    isNumberString(coinPrice) &&
    isNumberString(coinAmount as string)
    ? toDecimal(coinPrice).mul(coinAmount)
    : DECIMAL_ZERO;
};
